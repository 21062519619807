import { useMemo } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  DateField,
  NumberField,
  useTheme,
  usePermissions,
  FunctionField,
  RecordContextProvider,
  useTranslate,
  ChipField,
} from "react-admin";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import SaleReturnCardExpandList from "../../components/saleReturnCards/expand/SaleReturnCardExpandList";
import saleReturnCardFilterList from "../../components/saleReturnCards/filter/saleReturnCardFilterList";
import SaleReturnCardAsideList from "../../components/saleReturnCards/aside/SaleReturnCardAsideList";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const SaleReturnCardList = ({
  withActions = true,
  withFilters = true,
  grandTotalResource = "sale-return-cards",
  title = "mine.list_title.sale_return",
}) => {
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const [theme] = useTheme("light");

  const list = useMemo(() => {
    return (
      <>
        <List
          storeKey={false}
          resource="sale-return-cards"
          title={title}
          empty={false}
          aside={
            <SaleReturnCardAsideList
              name="RETUR JUAL PECAH"
              filter={{
                approved: 1,
                type: 2, // 2 artinya retur jual pecah
              }}
              withGrandTotal={false}
            />
          }
          actions={withActions ? <DownloadAction /> : false}
          filters={
            withFilters ? saleReturnCardFilterList(permissions) : undefined
          }
          filter={{
            approved: 1,
            type: 2,
          }}
          filterDefaultValues={{
            start_date: firstDate,
            end_date: lastDate,
            approved: 1,
          }}
          queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
          sx={{
            flexDirection: "column",
          }}
        >
          <DatagridConfigurable
            bulkActionButtons={false}
            expand={<SaleReturnCardExpandList />}
            sx={{
              "& .RaDatagrid-expandedPanel > td": {
                p: 0,
                bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
              },
            }}
          >
            {permissions && permissions.update_company ? (
              <TextField label="mine.label.company" source="company.alias" />
            ) : null}
            <TextField source="code" label="mine.label.code" />
            <DateField source="date" label="mine.label.date" />
            <FunctionField
              label="mine.label.customer"
              render={(record) =>
                `${record.customer.name} (${record.customer.code})`
              }
            />
            <FunctionField
              label="mine.tbl_header.type"
              textAlign="center"
              render={(record) => {
                let rec = {};
                switch (record.type) {
                  case 1:
                    rec.type = translate("mine.radio_btn.return_warehouse");
                    rec.color = "success";
                    break;
                  case 2:
                    rec.type = translate("mine.radio_btn.return_broken");
                    rec.color = "error";
                    break;
                  default:
                    rec.type = "-";
                    rec.color = "default";
                }
                return (
                  <RecordContextProvider value={rec}>
                    <ChipField source="type" color={rec.color} size="small" />
                  </RecordContextProvider>
                );
              }}
            />
            <FunctionField
              textAlign="right"
              label="Box"
              render={(record) => {
                let rec = { total_return: 0 };
                for (let i = 0; i < record.sale_return_items.length; i++) {
                  rec.total_return +=
                    +record.sale_return_items[i].return_quantity;
                }
                return (
                  <RecordContextProvider value={rec}>
                    <NumberField source="total_return" />
                  </RecordContextProvider>
                );
              }}
            />
            <NumberField source="total" label="mine.label.value" />
            <TextField source="note" label="mine.label.note" emptyText="-" />
          </DatagridConfigurable>
        </List>
        <div style={{ margin: "22px 0 8px 0" }} /> {/* ================ */}
        <List
          storeKey={false}
          resource="sale-return-cards"
          title=" "
          empty={false}
          aside={
            <SaleReturnCardAsideList
              name="RETUR JUAL BAIK"
              filter={{
                approved: 1,
                type: 1, // 1 artinya retur jual baik
              }}
              withGrandTotal={true}
              grandTotalResource={grandTotalResource}
            />
          }
          actions={false}
          filter={{
            approved: 1,
            type: 1,
          }}
          filterDefaultValues={{
            start_date: firstDate,
            end_date: lastDate,
          }}
          queryOptions={{
            meta: { sort: ["created_at DESC"] },
          }} // overide default sort
          sx={{
            flexDirection: "column",
          }}
        >
          <DatagridConfigurable
            bulkActionButtons={false}
            expand={<SaleReturnCardExpandList />}
            sx={{
              "& .RaDatagrid-expandedPanel > td": {
                p: 0,
                bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
              },
            }}
          >
            {permissions && permissions.update_company ? (
              <TextField label="mine.label.company" source="company.alias" />
            ) : null}
            <TextField source="code" label="mine.label.code" />
            <DateField source="date" label="mine.label.date" />
            <FunctionField
              label="mine.label.customer"
              render={(record) =>
                `${record.customer.name} (${record.customer.code})`
              }
            />
            <FunctionField
              label="mine.tbl_header.type"
              textAlign="center"
              render={(record) => {
                let rec = {};
                switch (record.type) {
                  case 1:
                    rec.type = translate("mine.radio_btn.return_warehouse");
                    rec.color = "success";
                    break;
                  case 2:
                    rec.type = translate("mine.radio_btn.return_broken");
                    rec.color = "error";
                    break;
                  default:
                    rec.type = "-";
                    rec.color = "default";
                }
                return (
                  <RecordContextProvider value={rec}>
                    <ChipField source="type" color={rec.color} size="small" />
                  </RecordContextProvider>
                );
              }}
            />
            <FunctionField
              textAlign="right"
              label="Box"
              render={(record) => {
                let rec = { total_return: 0 };
                for (let i = 0; i < record.sale_return_items.length; i++) {
                  rec.total_return +=
                    +record.sale_return_items[i].return_quantity;
                }
                return (
                  <RecordContextProvider value={rec}>
                    <NumberField source="total_return" />
                  </RecordContextProvider>
                );
              }}
            />
            <NumberField source="total" label="mine.label.value" />
            <TextField source="note" label="mine.label.note" emptyText="-" />
          </DatagridConfigurable>
        </List>
      </>
    );
  }, [theme]);
  return list;
};

export default SaleReturnCardList;
