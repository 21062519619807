import * as React from "react";
import {
  DatagridConfigurable,
  DateField,
  List,
  NumberField,
  PrevNextButtons,
  RecordContextProvider,
  TextField,
  TopToolbar,
  useGetOne,
  useGetRecordId,
  useListController,
  usePermissions,
  useTheme,
  useTranslate,
} from "react-admin";
import GeneralLedgerHeader from "../../components/generalLedgers/header/GeneralLedgerHeader";

const ListActions = ({ permanentFilter }) => {
  return React.useMemo(
    () => (
      <TopToolbar sx={{ mr: "auto", py: 0 }}>
        <PrevNextButtons
          linkType="show"
          limit={500}
          storeKey={false}
          resource="general-ledgers"
          filter={permanentFilter}
          sort={{ field: "chart_of_accounts.code", order: "ASC" }}
        />
      </TopToolbar>
    ),
    [permanentFilter.chart_of_account_id]
  );
};

// ada beberapa fetch ke api:
// - detail => ambil seluruh transaksi untuk 1 coa yang sedang dilihat
// - coa id => untuk ambil data nama dan code coa untuk ditampilkan di page title
// - coa id ke end point general-ledgers => sama seperti halaman list tapi hanya ambil satu coa saja untuk ditampilkan di atas header
// - general-ledgers dgn range 500 => triger dari <PrevNextButtons />
// - coa id => triger dari <PrevNextButtons />
const GeneralLedgerShow = () => {
  const resource = "general-ledgers/detail";

  const [theme] = useTheme("light");
  const { permissions } = usePermissions();
  const recordId = useGetRecordId();
  const t = useTranslate();
  const cntx = useListController({ storeKey: resource });

  // untuk page title
  const { data, isLoading, error } = useGetOne("coa", {
    id: encodeURIComponent(recordId),
  });

  const pageTitle =
    t("mine.list_title.general_ledger") +
    (!isLoading ? " - " + data.code + " " + data.name : "") +
    (error ? "cannot get coa name" : "");
  // untuk page title

  const permanentFilter = {
    ...cntx.filterValues,
    chart_of_account_id: recordId,
  };

  return (
    <List
      resource={resource}
      storeKey={false}
      title={pageTitle}
      disableSyncWithLocation
      emptyWhileLoading
      empty={false}
      actions={
        // PrevNextButtons butuh RecordContext yg otomatis ada di halaman edit dan show
        // sedangkan dihalaman list tidak ada jadi perlu di tambah manual
        // dan sebenarnya hanya butuh id nya saja
        <RecordContextProvider value={{ id: recordId }}>
          <ListActions permanentFilter={permanentFilter} />
        </RecordContextProvider>
      }
      // filters={generalLedgerFilterList(permissions)}
      //   filterDefaultValues={{
      //     chart_of_account_id: recordId,
      //     ...cntx.filterValues,
      //   }}
      filter={permanentFilter}
      sort={{ field: "transactions.date", order: "ASC" }}
      // queryOptions={{ meta: { sort: ["transactions.code ASC"] } }} // overide default sort
    >
      <DatagridConfigurable
        bulkActionButtons={false}
        rowClick={false}
        header={<GeneralLedgerHeader />}
        // expand={<AllTransactionJournalExpandList />}
        sx={{
          "& .RaDatagrid-expandedPanel > td": {
            p: 0,
            bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
          },
        }}
      >
        {permissions && permissions.update_company ? (
          <TextField source="company_name" label="mine.tbl_header.company" />
        ) : null}
        <DateField source="date" />
        <TextField source="code" label="mine.tbl_header.code" />
        <TextField source="description" label="mine.label.note" />
        <NumberField
          source="previous"
          label="mine.label.previous"
          // emptyText="0"
        />
        <NumberField source="debit" />
        <NumberField source="credit" />
        <NumberField
          source="current"
          label="mine.label.current_balance"
          // emptyText="0"
        />
      </DatagridConfigurable>
    </List>
  );
};
export default GeneralLedgerShow;
