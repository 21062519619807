import { DateInput, SelectInput, TextInput } from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

    
const activityFilterList = [
  <TextInput
    source="all"
    label="mine.filter.search_all"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput source="company" resettable />,
  <TextInput source="user" resettable />,
  <TextInput source="action_url" resettable />,
  <SelectInput
    source="req_method"
    resettable
    choices={[
      { id: "GET", name: "GET" },
      { id: "POST", name: "POST" },
      { id: "PUT", name: "PUT" },
      { id: "DELETE", name: "DELETE" },
    ]}
  />,
  <TextInput source="note" resettable />,
  <TextInput source="ip" resettable />,
  <DateInput source="created_at" />,
];

export default activityFilterList;
