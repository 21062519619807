import { Create, SaveButton, SimpleForm } from "react-admin";
import ReasonInput from "./Input";

const ReasonForm = ({ initialRecord, save, recordId, error }) => {
  return (
    <Create
      title={" "}
      // disableAuthentication
      record={initialRecord}
      sx={{
        "& .RaCreate-main": { mt: 0 },
        "& .RaCreate-card": { boxShadow: "none" },
        "& .RaCreate-card>form>.MuiCardContent-root": { p: 0 },
      }}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        onSubmit={save}
        toolbar={
          <SaveButton
            alwaysEnable
            icon={null}
            label=""
            className="sbt-btn-rsn"
            sx={{ display: "none" }}
          />
        }
        // mode="onBlur"
        // reValidateMode="onBlur"
      >
        <ReasonInput recordId={recordId} error={error} />
      </SimpleForm>
    </Create>
  );
};
export default ReasonForm;
