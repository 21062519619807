import { TableFooter, TableRow, TableCell } from "@mui/material";
import useGrandTotal from "../../hooks/useGrandTotal";
import { NumberField, RecordContextProvider, TextField } from "react-admin";

const AccountPayableAgeFooterList = () => {
  const { data, isLoading, error } = useGrandTotal();

  // if (error) return <Error />;

  return (
    <RecordContextProvider value={data ? data[0] : {}}>
      <TableFooter>
        <TableRow
          sx={{
            "& td": {
              borderTop: "1px solid rgba(224, 224, 224, 1) !important",
            },
            "& .MuiTypography-root": {
              color: (theme) => theme.palette.primary.main,
              fontWeight: "bold",
            },
          }}
        >
          <TableCell></TableCell>
          <TableCell colSpan={3}>
            <RecordContextProvider value={{ name: "GRAND TOTAL" }}>
              <TextField source="name" />
            </RecordContextProvider>
          </TableCell>
          <TableCell sx={{ textAlign: "right" }}>
            <NumberField source="grand_total_ap" textAlign="right" />
          </TableCell>
          <TableCell sx={{ textAlign: "right" }}>
            <NumberField source="grand_total_overdue" textAlign="right" />
          </TableCell>
          <TableCell sx={{ textAlign: "right" }}>
            <NumberField source="grand_total_less_30" textAlign="right" />
          </TableCell>
          <TableCell sx={{ textAlign: "right" }}>
            <NumberField source="grand_total_between_30_60" textAlign="right" />
          </TableCell>
          <TableCell sx={{ textAlign: "right" }}>
            <NumberField source="grand_total_between_61_90" textAlign="right" />
          </TableCell>
          <TableCell sx={{ textAlign: "right" }}>
            <NumberField
              source="grand_total_between_91_120"
              textAlign="right"
            />
          </TableCell>
          <TableCell sx={{ textAlign: "right" }}>
            <NumberField
              source="grand_total_between_121_150"
              textAlign="right"
            />
          </TableCell>
          <TableCell sx={{ textAlign: "right" }}>
            <NumberField source="grand_total_above_150" textAlign="right" />
          </TableCell>
        </TableRow>
      </TableFooter>
    </RecordContextProvider>
  );
};

export default AccountPayableAgeFooterList;
