// import logo from "./logo.svg";
// import "./App.css";
// import CoACreate from "./pages/coa/CoACreate";
// import BannerEdit from "./pages/banners/BannerEdit";
// import UserCreate from "./pages/users/UserCreate";
// import UserEdit from "./pages/users/UserEdit";
// import UserShow from "./pages/users/UserShow";
import {
  Admin,
  Resource,
  localStorageStore,
  radiantDarkTheme,
  radiantLightTheme,
} from "react-admin";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import CoAList from "./pages/coa/CoAList";
import UserList from "./pages/users/UserList";
import ActivityList from "./pages/activities/ActivityList";
import SalesAreaList from "./pages/salesAreas/SalesAreaList";
import Welcome from "./pages/dashboard/Welcome";
import CompanyList from "./pages/companies/CompanyList";
import BrandList from "./pages/brands/BrandList";
import SizeList from "./pages/sizes/SizeList";
import ColorList from "./pages/colors/ColorList";
import QualityList from "./pages/qualities/QualityList";
import ProductList from "./pages/products/ProductList";
import BrandPriceAdditionList from "./pages/brandPriceAdditions/BrandPriceAdditionList";
import SizePriceAdditionList from "./pages/sizePriceAdditions/SizePriceAdditionList";
import PriceList from "./pages/prices/PriceList";
import PriceLimitList from "./pages/priceLimits/PriceLimitList";
import DensityList from "./pages/densities/DensityList";
import MotifList from "./pages/motifs/MotifList";
import KindList from "./pages/kinds/KindList";
import SupplierList from "./pages/suppliers/SupplierList";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SalesmanList from "./pages/salesmen/SalesmanList";
import ManIcon from "@mui/icons-material/Man";
import DiscountIcon from "@mui/icons-material/Discount";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import WarehouseList from "./pages/warehouses/WarehouseList";
import FireTruckIcon from "@mui/icons-material/FireTruck";
import TransportList from "./pages/transports/TransportList";
import PromotionList from "./pages/promotions/PromotionList";
import PurchaseOrderList from "./pages/purchaseOrders/PurchaseOrderList";
import PurchaseList from "./pages/purchases/PurchaseList";
import PurchaseReturnList from "./pages/purchaseReturns/PurchaseReturnList";
import SaleReturnList from "./pages/saleReturns/SaleReturnList";
import SaleOrderList from "./pages/saleOrders/SaleOrderList";
import SaleList from "./pages/sales/SaleList";
import SalesmanTargetList from "./pages/salesmanTargets/SalesmanTargetList";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CustomerTypeList from "./pages/customerTypes/CustomerTypeList";
import CustomerList from "./pages/customers/CustomerList";
import StoreIcon from "@mui/icons-material/Store";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import CropIcon from "@mui/icons-material/Crop";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import OpacityIcon from "@mui/icons-material/Opacity";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import DifferenceIcon from "@mui/icons-material/Difference";
import GradeIcon from "@mui/icons-material/Grade";
import MoneyIcon from "@mui/icons-material/Money";
import CategoryIcon from "@mui/icons-material/Category";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import MyLayout from "./components/layout/Layout";
import { i18nProvider } from "./i18nProvider";
import FactoryDebtPaymentList from "./pages/factoryDebtPayments/FactoryDebtPaymentList";
import FactoryDebtCorrectionList from "./pages/factoryDebtCorrections/FactoryDebtCorrectionList";
import CustomerDebtPaymentList from "./pages/customerDebtPayments/CustomerDebtPaymentList";
import CustomerDebtCorrectionList from "./pages/customerDebtCorrections/CustomerDebtCorrectionList";
import CheckCashingList from "./pages/checkCashings/CheckCashingList";
import JournalList from "./pages/journals/JournalList";
import BadDebtList from "./pages/badDebts/BadDebtList";
import BadDebtInstallmentList from "./pages/badDebtInstallments/BadDebtInstallmentList";
import BadDebtCorrectionList from "./pages/badDebtCorrections/BadDebtCorrectionList";
import BadDebtCheckCashingList from "./pages/badDebtCheckCashings/BadDebtCheckCashingList";
import MutationRepackingList from "./pages/mutationRepackings/MutationRepackingList";
import MutationInventoryWriteOffList from "./pages/mutationInventoryWriteOffs/MutationInventoryWriteOffList";
import AllTransactionJournalList from "./pages/allTransactionJournals/AllTransactionJournalList";
import IncomeStatementList from "./pages/incomeStatements/IncomeStatementList";
import GeneralLedgerList from "./pages/generalLedgers/GeneralLedgerList";
import InventoryPendingList from "./pages/inventoryPendings/InventoryPendingList";
import PendingIcon from "@mui/icons-material/Pending";
import BookIcon from "@mui/icons-material/Book";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import PaymentsIcon from "@mui/icons-material/Payments";
import BalanceIcon from "@mui/icons-material/Balance";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import GeneralLedgerShow from "./pages/generalLedgers/GeneralLedgerShow";
import BalanceSheetsList from "./pages/balanceSheets/BalanceSheetsList";
import RoleList from "./pages/roles/RoleList";
import RoleEdit from "./pages/roles/RoleEdit";
import RoleCreate from "./pages/roles/RoleCreate";
import PermissionList from "./pages/permissions/PermissionList";
import PermissionEdit from "./pages/permissions/PermissionEdit";
import PermissionCreate from "./pages/permissions/PermissionCreate";
import CloseBookList from "./pages/closeBooks/CloseBookList";
import AccountReceivableCardList from "./pages/accountReceivableCards/AccountReceivableCardList";
import CustomerDebtMaxDayList from "./pages/customerDebtMaxDay/CustomerDebtMaxDayList";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LoginPage from "./pages/login/LoginPage";
import AccountPayableCardList from "./pages/accountPayableCards/AccountPayableCardList";
import InventoryCardList from "./pages/inventoryCards/InventoryCardList";
import PurchaseNetCardList from "./pages/purchaseNetCards/PurchaseNetCardList";
import PurchaseReturnCardList from "./pages/purchaseReturnCards/PurchaseReturnCardList";
import SaleReturnCardList from "./pages/saleReturnCards/SaleReturnCardList";
import SaleNetCardList from "./pages/saleNetCards/SaleNetCardList";
import InventoryAgeList from "./pages/inventoryAges/InventoryAgeList";
import AccountReceivableAgingList from "./pages/accountReceivableAgings/AccountReceivableAgingList";
import AccountReceivablePcmList from "./pages/accountReceivablePcms/AccountReceivablePcmList";
import AccountReceivableHistoryList from "./pages/accountReceivableHistories/AccountReceivableHistoryList";
import AccountReceivablePcmCardList from "./pages/accountReceivablePcmCards/AccountReceivablePcmCardList";
import AccountReceivablePcmAgeList from "./pages/accountReceivablePcmAges/AccountReceivablePcmAgeList";
import AccountPayableAgeList from "./pages/accountPayableAges/AccountPayableAgeList";
import AccountPayableHistoryList from "./pages/accountPayableHistories/AccountPayableHistoryList";
import InventoryHistoryList from "./pages/inventoryHistories/InventoryHistoryList";
import SaleRankingList from "./pages/saleRankings/SaleRankingList";
import ProductRankingList from "./pages/productRankings/ProductRankingList";
import CustomerRankingList from "./pages/customerRankings/CustomerRankingList";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";

// const darkTheme = {
//   ...defaultTheme,
//   palette: { mode: "dark" },
// };

// setiap deploy baru versinya perlu dirubah
// tergantung kebutuhan, tp sebaiknya di rubah saja
const STORE_VERSION = "9"; // see: https://marmelab.com/react-admin/Store.html#store-invalidation

const App = () => (
  <Admin
    store={localStorageStore(STORE_VERSION)}
    disableTelemetry
    dashboard={Welcome}
    dataProvider={dataProvider}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
    layout={MyLayout}
    loginPage={LoginPage}
    theme={radiantLightTheme}
    // lightTheme={radiantLightTheme}
    darkTheme={radiantDarkTheme}
    defaultTheme="light"
  >
    {(permissions) => {
      return (
        <>
          <Resource
            name="companies"
            list={CompanyList}
            icon={LocationCityIcon}
            options={{ label: "resources.company.name" }}
            // edit={UserEdit}
            // create={UserCreate}
            // show={UserShow}
          />
          <Resource
            name="roles"
            list={RoleList}
            icon={LocationCityIcon}
            // options={{ label: "resources.role.name" }}
            edit={RoleEdit}
            create={RoleCreate}
          />
          <Resource
            name="permissions"
            list={PermissionList}
            icon={LocationCityIcon}
            // options={{ label: "resources.permission.name" }}
            edit={PermissionEdit}
            create={PermissionCreate}
          />
          <Resource
            options={{ label: "resources.chart_of_account.name" }}
            name="coa"
            list={CoAList}
            icon={AccountTreeIcon}
            // edit={CoAEdit}
            // create={CoACreate}
          />
          <Resource
            name="sales-areas"
            list={SalesAreaList}
            options={{ label: "resources.sales_area.name" }}
            icon={ShareLocationIcon}
          />
          <Resource
            name="customer-types"
            list={CustomerTypeList}
            options={{ label: "resources.customer_type.name" }}
          />
          <Resource
            name="customers"
            list={CustomerList}
            icon={StoreIcon}
            options={{ label: "resources.customer.name" }}
          />
          <Resource
            name="suppliers"
            list={SupplierList}
            icon={LocalShippingIcon}
            options={{ label: "resources.supplier.name" }}
          />
          <Resource
            name="brands"
            list={BrandList}
            icon={BrandingWatermarkIcon}
            options={{ label: "resources.brand.name" }}
          />
          <Resource
            name="sizes"
            list={SizeList}
            icon={CropIcon}
            options={{ label: "resources.size.name" }}
          />
          <Resource name="motifs" list={MotifList} icon={AcUnitIcon} />
          <Resource
            name="kinds"
            list={KindList}
            icon={DifferenceIcon}
            options={{ label: "resources.kind.name" }}
          />
          <Resource
            name="densities"
            list={DensityList}
            icon={OpacityIcon}
            options={{ label: "resources.density.name" }}
          />
          <Resource
            name="colors"
            list={ColorList}
            icon={ColorLensIcon}
            options={{ label: "resources.color.name" }}
          />
          <Resource
            name="qualities"
            list={QualityList}
            icon={GradeIcon}
            options={{ label: "resources.quality.name" }}
          />
          <Resource
            name="products"
            list={ProductList}
            icon={CategoryIcon}
            options={{ label: "resources.product.name" }}
          />
          <Resource
            name="prices"
            list={PriceList}
            icon={MoneyIcon}
            options={{ label: "resources.price.name" }}
          />
          <Resource
            name="price-limits"
            list={PriceLimitList}
            icon={PriceCheckIcon}
            options={{ label: "resources.price_limit.name" }}
          />
          <Resource
            name="brand-price-additions"
            list={BrandPriceAdditionList}
            icon={PriceChangeIcon}
            options={{ label: "resources.brand_price_addition.name" }}
          />
          <Resource
            name="size-price-additions"
            list={SizePriceAdditionList}
            icon={PriceChangeIcon}
            options={{ label: "resources.size_price_addition.name" }}
          />
          <Resource
            name="promotions"
            list={PromotionList}
            options={{ label: "mine.label.promotions" }}
            icon={DiscountIcon}
          />
          <Resource
            name="salesmen"
            list={SalesmanList}
            options={{ label: "Salesmen" }}
            icon={ManIcon}
          />
          <Resource
            name="salesman-targets"
            list={SalesmanTargetList}
            options={{ label: "Salesman Targets" }}
            icon={TrendingUpIcon}
          />
          <Resource
            name="warehouses"
            list={WarehouseList}
            icon={WarehouseIcon}
            options={{ label: "resources.warehouse.name" }}
          />
          <Resource
            name="transports"
            list={TransportList}
            icon={FireTruckIcon}
            options={{ label: "resources.transport.name" }}
          />
          <Resource
            name="users"
            list={UserList}
            icon={PeopleAltIcon}
            options={{ label: "resources.user.name" }}
            // edit={UserEdit}
            // create={UserCreate}
            // show={UserShow}
          />
          <Resource
            name="activities"
            list={ActivityList}
            icon={ListAltIcon}
            options={{ label: "resources.activity.name" }}
          />
          <Resource
            name="purchase-orders"
            list={PurchaseOrderList}
            icon={FiberManualRecordOutlinedIcon}
            options={{ label: "resources.order.name" }}
          />
          <Resource
            name="purchases"
            list={PurchaseList}
            icon={FiberManualRecordOutlinedIcon}
            options={{ label: "resources.purchase.name" }}
          />
          <Resource
            name="purchase-returns"
            list={PurchaseReturnList}
            icon={FiberManualRecordOutlinedIcon}
            options={{ label: "resources.return.name" }}
          />
          <Resource
            name="sale-orders"
            list={SaleOrderList}
            icon={FiberManualRecordOutlinedIcon}
            options={{ label: "resources.order.name" }}
          />
          <Resource
            name="sales"
            list={SaleList}
            icon={FiberManualRecordOutlinedIcon}
            options={{ label: "resources.sale.name" }}
          />
          <Resource
            name="sale-returns"
            list={SaleReturnList}
            icon={FiberManualRecordOutlinedIcon}
            options={{ label: "resources.return.name" }}
          />
          <Resource
            name="factory-debt-payments"
            list={FactoryDebtPaymentList}
            icon={FiberManualRecordOutlinedIcon}
            options={{ label: "resources.debt_payment.name" }}
          />
          <Resource
            name="factory-debt-corrections"
            list={FactoryDebtCorrectionList}
            icon={FiberManualRecordOutlinedIcon}
            options={{ label: "resources.debt_correction.name" }}
          />
          <Resource
            name="customer-debt-payments"
            list={CustomerDebtPaymentList}
            icon={FiberManualRecordOutlinedIcon}
            options={{ label: "resources.debt_payment.name" }}
          />
          <Resource
            name="customer-debt-corrections"
            list={CustomerDebtCorrectionList}
            icon={FiberManualRecordOutlinedIcon}
            options={{ label: "resources.debt_correction.name" }}
          />
          <Resource
            name="check-cashing"
            list={CheckCashingList}
            icon={PaymentsIcon}
            options={{ label: "resources.check_cashing.name" }}
          />
          <Resource
            name="journals"
            list={JournalList}
            icon={HistoryEduIcon}
            options={{ label: "resources.journal.name" }}
          />
          <Resource
            name="bad-debts"
            list={BadDebtList}
            icon={FiberManualRecordOutlinedIcon}
            options={{ label: "resources.bad_debt.name" }}
          />
          <Resource
            name="bad-debt-installments"
            list={BadDebtInstallmentList}
            icon={FiberManualRecordOutlinedIcon}
            options={{ label: "resources.bad_debt_installment.name" }}
          />
          <Resource
            name="bad-debt-corrections"
            list={BadDebtCorrectionList}
            icon={FiberManualRecordOutlinedIcon}
            options={{ label: "resources.bad_debt_correction.name" }}
          />
          <Resource
            name="bad-debt-check-cashing"
            list={BadDebtCheckCashingList}
            icon={FiberManualRecordOutlinedIcon}
            options={{ label: "resources.bad_debt_check_cashing.name" }}
          />
          <Resource
            name="mutation-repacking"
            list={MutationRepackingList}
            icon={FiberManualRecordOutlinedIcon}
            options={{ label: "resources.mutation_repacking.name" }}
          />
          <Resource
            name="mutation-inventory-write-offs"
            list={MutationInventoryWriteOffList}
            icon={FiberManualRecordOutlinedIcon}
            options={{ label: "resources.mutation_inventory_write_off.name" }}
          />
          <Resource
            name="inventory-pendings"
            list={InventoryPendingList}
            icon={PendingIcon}
            options={{ label: "resources.inventory_pending.name" }}
          />
          <Resource
            name="close-books"
            list={CloseBookList}
            icon={BookIcon}
            options={{ label: "resources.close_book.name" }}
          />
          {/* <Resource
            name="mutation-change-goods-codes"
            list={BadDebtCheckCashingList}
            icon={FiberManualRecordOutlinedIcon}
            options={{ label: "resources.mutation_change_goods_code.name" }}
          />
          <Resource
            name="mutation-change-locations"
            list={BadDebtCheckCashingList}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.mutation_change_location.name",
            }}
          /> */}
          <Resource
            name="balance-sheets"
            list={BalanceSheetsList}
            icon={BalanceIcon}
            options={{
              label: "resources.balance_sheets.name",
            }}
          />
          <Resource
            name="income-statements"
            list={IncomeStatementList}
            icon={RequestQuoteIcon}
            options={{
              label: "resources.income_statements.name",
            }}
          />
          <Resource
            name="general-ledgers"
            list={GeneralLedgerList}
            show={GeneralLedgerShow}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.general_ledgers.name",
            }}
          />
          <Resource
            name="purchase-net-cards"
            list={<PurchaseNetCardList title="mine.label.net_purchase" />}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "mine.label.net",
            }}
          />
          <Resource
            name="purchase-cards"
            list={<PurchaseList title="mine.label.gross_purchase" />}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "mine.label.gross",
            }}
          />
          <Resource
            name="purchase-return-cards"
            list={PurchaseReturnCardList}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.return.name",
            }}
          />
          <Resource
            name="sale-net-cards"
            list={<SaleNetCardList title="mine.label.net_sale" />}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "mine.label.net",
            }}
          />
          <Resource
            name="sale-cards"
            list={<SaleList title="mine.label.gross_sale" />}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "mine.label.gross",
            }}
          />
          <Resource
            name="sale-return-cards"
            list={SaleReturnCardList}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.return.name",
            }}
          />
          <Resource
            name="sale-rankings"
            list={SaleRankingList}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.ranking.name",
            }}
          />
          <Resource
            name="account-receivable-cards"
            list={AccountReceivableCardList}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.account_receivable_cards.name",
            }}
          />
          <Resource
            name="account-receivable-agings"
            list={AccountReceivableAgingList}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.account_receivable_agings.name",
            }}
          />
          <Resource
            name="account-receivable-histories"
            list={AccountReceivableHistoryList}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.account_receivable_histories.name",
            }}
          />
          <Resource
            name="account-receivable-pcms"
            list={AccountReceivablePcmList}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.account_receivable_pcms.name",
            }}
          />
          <Resource
            name="account-receivable-pcm-cards"
            list={AccountReceivablePcmCardList}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.account_receivable_pcm_cards.name",
            }}
          />
          <Resource
            name="account-receivable-pcm-ages"
            list={AccountReceivablePcmAgeList}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.account_receivable_pcm_ages.name",
            }}
          />
          <Resource
            name="account-payable-cards"
            list={AccountPayableCardList}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.account_payable_cards.name",
            }}
          />
          <Resource
            name="account-payable-ages"
            list={AccountPayableAgeList}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.account_payable_ages.name",
            }}
          />
          <Resource
            name="account-payable-histories"
            list={AccountPayableHistoryList}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.account_payable_histories.name",
            }}
          />
          <Resource
            name="inventory-cards"
            list={InventoryCardList}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.inventory_cards.name",
            }}
          />
          <Resource
            name="inventory-ages"
            list={InventoryAgeList}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.inventory_ages.name",
            }}
          />
          <Resource
            name="inventory-histories"
            list={InventoryHistoryList}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.inventory_histories.name",
            }}
          />
          <Resource
            name="product-rankings"
            list={ProductRankingList}
            icon={TrendingUpOutlinedIcon}
            options={{
              label: "resources.product_rankings.name",
            }}
          />
          <Resource
            name="customer-rankings"
            list={CustomerRankingList}
            icon={BarChartOutlinedIcon}
            options={{
              label: "resources.customer_rankings.name",
            }}
          />
          <Resource
            name="all-transaction-journals"
            list={AllTransactionJournalList}
            icon={FiberManualRecordOutlinedIcon}
            options={{
              label: "resources.all_transaction_journals.name",
            }}
          />
          <Resource
            name="customer-debt-max-days"
            list={CustomerDebtMaxDayList}
            icon={ScheduleIcon}
            options={{
              label: "resources.customer_debt_max_days.name",
            }}
          />
        </>
      );
    }}
  </Admin>
);

export default App;
