import {
  useDataProvider,
  useListContext,
  useResourceContext,
} from "react-admin";
import { useQuery } from "react-query";

const useGrandTotal = (subUri = "grand-total", filter = {}) => {
  const resource = useResourceContext();
  const dataProvider = useDataProvider();
  const { filterValues } = useListContext();

  let ifCtx;
  if (Object.keys(filterValues).length) {
    ifCtx = { ...filterValues, ...filter };
  }

  const resourceFilter =
    resource +
    "/" +
    subUri +
    (ifCtx ? "?filter=" + encodeURIComponent(JSON.stringify(ifCtx)) : "");

  return useQuery([resourceFilter, "getListNoParams"], () =>
    dataProvider.getListNoParams(resourceFilter)
  );
};

export default useGrandTotal;
