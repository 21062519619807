import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DateField,
  FunctionField,
  BulkDeleteWithConfirmButton,
  useTheme,
  NumberField,
  ChipField,
  RecordContextProvider,
  usePermissions,
  useResourceContext,
  useTranslate,
} from "react-admin";
import TableListActions from "../../components/tableListActions/TableListActions";
import inventoryPendingFilterList from "../../components/inventoryPendings/filter/inventoryPendingFilterList";
import ConfirmBtn from "../../components/confirmBtn/ConfirmBtn";
import CloseIcon from "@mui/icons-material/Close";
import BlockIcon from "@mui/icons-material/Block";

const Aside = () => {
  const t = useTranslate();
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <h5>
        Pembatalan hanya untuk SO yang sebagiannya sudah dikirim dan statusnya
        Pending, SO yang sama sekali belum dikirim tidak dapat di batalkan,
        karena sama saja menghapus SO tersebut. Jika ingin menghapus bisa
        dilakukan di menu {t("mine.expand_menu.sale")} {">"}{" "}
        {t("mine.expand_menu.order")}
      </h5>
    </div>
  );
};

const InventoryPendingList = () => {
  const [showAside, setShowAside] = useState(false);

  const resource = useResourceContext();
  const [theme] = useTheme("light");
  const { permissions } = usePermissions();

  const rowClickHandler = (id, basePath, record) => {
    // console.log("Lets", id, basePath, record);
    setShowAside((prev) => !prev);
  };

  const list = useMemo(
    () => (
      <List
        title="mine.list_title.inventory_pending"
        empty={false}
        aside={showAside ? <Aside /> : null}
        actions={<TableListActions />}
        filters={inventoryPendingFilterList(permissions)}
        filterDefaultValues={{ status: 1 }}
        queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          rowClick={rowClickHandler}
          bulkActionButtons={false}
        >
          {permissions && permissions.update_company ? (
            <TextField source="company.alias" label="mine.label.company" />
          ) : null}
          <TextField
            source="sale_order_item.sale_order.code"
            label="mine.label.sale_order"
          />
          <DateField
            source="sale_order_item.sale_order.date"
            label="mine.label.date"
          />
          <TextField source="product.code" label="mine.label.code" />
          <TextField source="product.name" label="mine.label.name" />
          <NumberField source="order" />
          <NumberField source="sent" />
          <NumberField source="pending" />
          <FunctionField
            label="Status"
            textAlign="center"
            render={(record) => {
              let rec = {};
              switch (record.status) {
                case 1:
                  rec.status = "Pending";
                  rec.color = "warning";
                  break;
                case 2:
                  rec.status = "Done";
                  rec.color = "success";
                  break;
                case 3:
                  rec.status = "Canceled";
                  rec.color = "info";
                  break;
                default:
                  rec.status = "-";
                  rec.color = "default";
              }
              return (
                <RecordContextProvider value={rec}>
                  <ChipField source="status" color={rec.color} size="small" />
                </RecordContextProvider>
              );
            }}
          />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              render={(record) => {
                return record.status === 1 && record.sent !== 0 ? (
                  <ConfirmBtn
                    title={"mine.message.cancel"}
                    content="mine.message.cancel_content"
                    label="ra.action.cancel"
                    resource={resource + "/cancel"}
                    startIcon={<CloseIcon />}
                    variant="outlined"
                    color="error"
                    // sx={{
                    //   mx: 1,
                    //   "& > .MuiButton-startIcon": {
                    //     mr: 0,
                    //   },
                    // }}
                  />
                ) : (
                  <BlockIcon color="error" />
                );
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    [showAside, theme]
  );
  return list;
};

export default InventoryPendingList;
