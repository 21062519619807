import {
  useRecordContext,
  RecordContextProvider,
  NumberField,
  useTranslate,
  DateField,
  TextField,
} from "react-admin";
import Box from "@mui/material/Box";

const perSJAndJT = (record, t) => {
  return (
    <>
      <thead>
        <tr>
          <th style={{ width: "5.6%" }}>SJ {t("mine.label.date")}</th>
          <th style={{ width: "5.6%" }}>{t("mine.label.due")}</th>
          <th style={{ width: "6.6%" }}>SJ {t("mine.label.code")}</th>
          <th style={{ textAlign: "right", width: "7.3%" }}>
            {t("mine.label.debt")}
          </th>
          <th style={{ textAlign: "right", width: "3.9%" }}>
            {t("mine.label.day")}
          </th>
          <th style={{ textAlign: "right", width: "7.3%" }}>Overdue</th>
          <th style={{ textAlign: "right", width: "7.3%" }}>
            {"< 30 " + t("mine.label.day")}
          </th>
          <th style={{ textAlign: "right", width: "7.3%" }}>
            {"30 - 60 " + t("mine.label.day")}
          </th>
          <th style={{ textAlign: "right", width: "7.3%" }}>
            {"61 - 90 " + t("mine.label.day")}
          </th>
          <th style={{ textAlign: "right", width: "7.3%" }}>
            {"91 - 120 " + t("mine.label.day")}
          </th>
          <th style={{ textAlign: "right", width: "7.3%" }}>
            {"121 - 150 " + t("mine.label.day")}
          </th>
          <th style={{ textAlign: "right", width: "7.3%" }}>
            {"> 150 " + t("mine.label.day")}
          </th>
        </tr>
      </thead>
      <tbody>
        {record.company_debts.map((item, idx) => {
          return (
            <tr key={item.id + idx}>
              <td>
                <RecordContextProvider value={item}>
                  <DateField
                    source="purchase_order.date"
                    sx={{
                      fontSize: "inherit",
                    }}
                  />
                </RecordContextProvider>
              </td>
              <td>
                <RecordContextProvider value={item}>
                  <DateField
                    source="due_date"
                    sx={{
                      fontSize: "inherit",
                    }}
                  />
                </RecordContextProvider>
              </td>
              <td>
                <RecordContextProvider value={item}>
                  <TextField
                    source="purchase_order.code"
                    sx={{
                      fontSize: "inherit",
                    }}
                  />
                </RecordContextProvider>
              </td>
              <td style={{ textAlign: "right" }}>
                <RecordContextProvider value={item}>
                  <NumberField
                    source="remain"
                    sx={{
                      fontSize: "inherit",
                    }}
                  />
                </RecordContextProvider>
              </td>
              <td style={{ textAlign: "right" }}>
                <RecordContextProvider value={item}>
                  <NumberField
                    source="days"
                    sx={{
                      fontSize: "inherit",
                    }}
                  />
                </RecordContextProvider>
              </td>
              <td style={{ textAlign: "right" }}>
                <RecordContextProvider value={item}>
                  <NumberField
                    source="overdue"
                    sx={{
                      fontSize: "inherit",
                    }}
                  />
                </RecordContextProvider>
              </td>
              <td style={{ textAlign: "right" }}>
                <RecordContextProvider value={item}>
                  <NumberField
                    source="less_30"
                    sx={{
                      fontSize: "inherit",
                    }}
                  />
                </RecordContextProvider>
              </td>
              <td style={{ textAlign: "right" }}>
                <RecordContextProvider value={item}>
                  <NumberField
                    source="between_30_60"
                    sx={{
                      fontSize: "inherit",
                    }}
                  />
                </RecordContextProvider>
              </td>
              <td style={{ textAlign: "right" }}>
                <RecordContextProvider value={item}>
                  <NumberField
                    source="between_61_90"
                    sx={{
                      fontSize: "inherit",
                    }}
                  />
                </RecordContextProvider>
              </td>
              <td style={{ textAlign: "right" }}>
                <RecordContextProvider value={item}>
                  <NumberField
                    source="between_91_120"
                    sx={{
                      fontSize: "inherit",
                    }}
                  />
                </RecordContextProvider>
              </td>
              <td style={{ textAlign: "right" }}>
                <RecordContextProvider value={item}>
                  <NumberField
                    source="between_121_150"
                    sx={{
                      fontSize: "inherit",
                    }}
                  />
                </RecordContextProvider>
              </td>
              <td style={{ textAlign: "right" }}>
                <RecordContextProvider value={item}>
                  <NumberField
                    source="above_150"
                    sx={{
                      fontSize: "inherit",
                    }}
                  />
                </RecordContextProvider>
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

const AccountPayableAgeExpandList = () => {
  const record = useRecordContext();
  const t = useTranslate();

  let items;

  if (record.company_debts) {
    items = perSJAndJT(record, t);
  }

  return items ? (
    <Box
      sx={{
        m: 5,
        backgroundClip: "padding-box",
        boxShadow: (theme) => {
          return theme.components.MuiPaper.styleOverrides.elevation1.boxShadow;
        },
        borderRadius: "4px",
        bgcolor: "background.paper",
      }}
    >
      <Box
        component="table"
        sx={{
          width: "100%",
          // bgcolor: "rgba(0,0,0,.04)",
          fontSize: "smaller",
          borderCollapse: "collapse",
          "& th, td": {
            padding: "6px 8px",
            border: "1px solid #dddddd",
          },
        }}
      >
        {items}
      </Box>
    </Box>
  ) : null;
};

export default AccountPayableAgeExpandList;
