import { forwardRef, Fragment } from "react";
import Box from "@mui/material/Box";
import classes from "./PrintContent.module.css";
import Typography from "@mui/material/Typography";
import {
  DateField,
  NumberField,
  RecordContextProvider,
  // usePermissions,
} from "react-admin";
import splitArrayToChunks from "../../../utils/splitArrayToChunks";

const PrintContent = forwardRef((props, ref) => {
  // eslint-disable-line max-len
  // const { permissions, isLoading } = usePermissions();

  return (
    <Box
      component="div"
      sx={{
        display: "none",
      }}
    >
      <Box
        ref={ref}
        sx={{
          fontSize: "12px",
          py: 2,
          px: 2,
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 40px)",
          fontFamily: "'Source Code Pro', monospace",
        }}
      >
        <style type="text/css" media="print">
          {`@page {
              width: 215.9mm;
              height: 139.7mm;
              size: 8.5in 5.5in portrait;
              margin: 0;
            }
            html, body {
              width: 215.9mm;
              height: 100%;
            }
            .page-break {
              display: block;
              page-break-after: always;
            }
          `}
        </style>
        {splitArrayToChunks(props.record.factory_debt_correction_items, 2).map(
          (arr, i, ori) => {
            return (
              <Fragment key={i}>
                <Box sx={i > 0 ? { mt: 2 } : {}}></Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      fontFamily: "'Source Code Pro', monospace",
                    }}
                  >
                    {props.record.company.name}
                  </Typography>
                  <Box>
                    <Typography
                      variant="caption"
                      component="span"
                      sx={{
                        pr: 1,
                        fontFamily: "'Source Code Pro', monospace",
                      }}
                    >
                      Print date:
                    </Typography>
                    <RecordContextProvider value={props.record}>
                      <DateField
                        source="print_date"
                        sx={{
                          fontWeight: "inherit",
                          fontSize: "12px",
                          fontFamily: "'Source Code Pro', monospace",
                        }}
                      ></DateField>
                    </RecordContextProvider>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    mb: 2,
                    textTransform: "uppercase",
                    borderBottom: "1px solid #000",
                    textAlign: "center",
                    fontFamily: "'Source Code Pro', monospace",
                  }}
                >
                  koreksi hutang
                </Typography>
                <table className={classes.headerSj}>
                  <thead>
                    <tr>
                      <th rowSpan={5} style={{ width: "70%" }}>
                        <div style={{ fontWeight: "400", marginBottom: "6px" }}>
                          KEPADA YTH:{" "}
                        </div>
                        <div style={{ paddingLeft: "16px", fontWeight: "400" }}>
                          {props.record.supplier.code}
                        </div>
                        <div style={{ paddingLeft: "16px" }}>
                          {props.record.supplier.name}
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th>No. Voucher</th>
                      <th>:</th>
                      <th>{props.record.code}</th>
                    </tr>
                    <tr>
                      <th>Tgl. Bukti</th>
                      <th>:</th>
                      <th>
                        <RecordContextProvider value={props.record}>
                          <DateField
                            source="date"
                            sx={{
                              fontWeight: "inherit",
                              fontSize: "12px",
                              fontFamily: "inherit",
                            }}
                          ></DateField>
                        </RecordContextProvider>
                      </th>
                    </tr>
                  </thead>
                </table>
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mb: 1,
                    fontFamily: "'Source Code Pro', monospace",
                  }}
                >
                  Keterangan: {props.record.note}
                </Typography>
                <table className={classes.tableSj}>
                  <thead>
                    <tr>
                      <th>No. Bukti Surat Jalan</th>
                      <th>Kode COA</th>
                      <th>Nama COA</th>
                      <th style={{ textAlign: "right" }}>Nominal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arr.map((obj, idx) => {
                      return (
                        <tr key={obj.id}>
                          <td>{obj.purchase_order.code}</td>
                          <td>
                            {
                              props.record.factory_debt_correction_journals[1]
                                .chart_of_account.code
                            }
                          </td>
                          <td>
                            {
                              props.record.factory_debt_correction_journals[1]
                                .chart_of_account.name
                            }
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <RecordContextProvider value={obj}>
                              <NumberField
                                source="amount"
                                sx={{
                                  fontWeight: "inherit",
                                  fontSize: "12px",
                                  fontFamily: "inherit",
                                }}
                              ></NumberField>
                            </RecordContextProvider>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        colSpan={3}
                        style={{
                          textAlign: "right",
                          verticalAlign: "baseline",
                          fontWeight: "bold",
                          borderRight: "none",
                        }}
                      >
                        Total:
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={props.record}>
                          <NumberField
                            source="total"
                            sx={{
                              fontWeight: "inherit",
                              fontSize: "12px",
                              fontFamily: "inherit",
                            }}
                          ></NumberField>
                        </RecordContextProvider>
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <Typography
                  variant="caption"
                  sx={{
                    mt: "auto",
                    textTransform: "capitalize",
                    fontFamily: "'Source Code Pro', monospace",
                  }}
                >
                  {"Terbilang: # " + props.record.total_in_words + " Rupiah #"}
                </Typography>
                <table
                  style={{
                    marginTop: "8px",
                  }}
                  className={classes.tableACC}
                >
                  <thead>
                    <tr>
                      <th>Mengetahui</th>
                      <th colSpan={2}>Pemeriksa</th>
                    </tr>
                    <tr>
                      <th
                        style={{ textTransform: "uppercase", width: "33.33%" }}
                      >
                        Kepala Depo
                      </th>
                      <th
                        style={{ textTransform: "uppercase", width: "33.33%" }}
                      >
                        Akunting
                      </th>
                      <th
                        style={{ textTransform: "uppercase", width: "33.33%" }}
                      >
                        Administrasi
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td style={{ paddingTop: "60px" }}></td>
                    </tr>
                  </tbody>
                </table>
                <div className="page-break" />
              </Fragment>
            );
          }
        )}
      </Box>
    </Box>
  );
});
export default PrintContent;
