import { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import { useTranslate, useSidebarState, useTheme } from "react-admin";
import { useLocation } from "react-router-dom";

// see: https://stackoverflow.com/a/73153767
const SubMenu = (props) => {
  const {
    isDropdownOpen = false,
    url,
    primaryText,
    leftIcon,
    children,
    ...rest
  } = props;
  const translate = useTranslate();
  const [open] = useSidebarState();
  const [isOpen, setIsOpen] = useState(isDropdownOpen);
  const location = useLocation();
  const [theme] = useTheme("light");

  useEffect(() => {
    if (Array.isArray(url)) {
      for (let i = 0; i < url.length; i++) {
        const str = url[i];
        if (location.pathname === "/" + str) {
          setIsOpen(true);
          break;
        } else {
          setIsOpen(false);
        }
      }
    } else {
      if (location.pathname.startsWith("/" + url)) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  }, [location, url]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ListItemButton
        dense
        onClick={handleToggle}
        sx={{
          paddingLeft: "1rem",
          color: theme !== "dark" ? "text.secondary" : "text.primary",
        }}
      >
        {leftIcon}
        <ListItemText
          inset
          disableTypography
          primary={translate(primaryText)}
          sx={{
            paddingLeft: 2,
            fontSize: "1rem",
            color: "text.secondary",
            textTransform: "capitalize",
          }}
        />
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={
            open
              ? {
                  paddingLeft: "25px",
                  transition:
                    "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                }
              : {
                  paddingLeft: 0,
                  transition:
                    "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                }
          }
        >
          {children}
        </List>
      </Collapse>
    </>
  );
};

// export type SubMenuProps = {
//   children?: React.ReactNode,
//   isDropdownOpen?: boolean,
//   leftIcon?: React.ReactElement,
//   primaryText?: string,
// };

export default SubMenu;
