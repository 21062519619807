import * as React from "react";
import {
  Error,
  RecordContextProvider,
  NumberField,
  TextField,
  useTranslate,
  Labeled,
} from "react-admin";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import useGrandTotal from "../../hooks/useGrandTotal";

const AccountReceivableAgingAsideList = () => {
  const { data, isLoading, error } = useGrandTotal();

  // const resource = useResourceContext();
  // const dataProvider = useDataProvider();
  // const { filterValues } = useListContext();

  const t = useTranslate();

  const dayTrans = t("mine.label.day");

  // let ifCtx;
  // if (Object.keys(filterValues).length) {
  //   ifCtx = filterValues;
  // }

  // const resourceFilter =
  //   resource +
  //   "/grand-total" +
  //   (ifCtx ? "?filter=" + encodeURIComponent(JSON.stringify(ifCtx)) : "");

  // const { data, isLoading, error } = useQuery(
  //   [resourceFilter, "getListNoParams"],
  //   () => dataProvider.getListNoParams(resourceFilter)
  // );

  if (error) return <Error />;

  return (
    <Card
      sx={{
        mt: 1,
        position: "sticky",
        bottom: 0,
        alignSelf: "stretch",
      }}
    >
      <CardContent sx={{ ":last-child": { p: 0 } }}>
        <RecordContextProvider value={data ? data[0] : {}}>
          <Box
            component="table"
            sx={{
              width: "100%",
              "& td, th": {
                px: "10px",
              },
              "& td .MuiTypography-root": {
                fontWeight: "bold",
              },
              "& th": {
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              },
            }}
          >
            <tbody>
              <tr>
                <td>
                  <RecordContextProvider value={{ name: "GRAND TOTAL" }}>
                    <TextField source="name" />
                  </RecordContextProvider>
                </td>
                <td style={{ textAlign: "right" }}>
                  <Labeled>
                    <NumberField
                      source="grand_total_ar"
                      label="mine.label.customer_debt"
                      textAlign="right"
                    />
                  </Labeled>
                </td>
                <td style={{ textAlign: "right" }}>
                  <Labeled>
                    <NumberField
                      source="grand_total_overdue"
                      label="Overdue"
                      textAlign="right"
                    />
                  </Labeled>
                </td>
                <td style={{ textAlign: "right" }}>
                  <Labeled>
                    <NumberField
                      source="grand_total_less_30"
                      label={"< 30 " + dayTrans}
                      textAlign="right"
                    />
                  </Labeled>
                </td>
                <td style={{ textAlign: "right" }}>
                  <Labeled>
                    <NumberField
                      source="grand_total_between_30_60"
                      label={"30 - 60 " + dayTrans}
                      textAlign="right"
                    />
                  </Labeled>
                </td>
                <td style={{ textAlign: "right" }}>
                  <Labeled>
                    <NumberField
                      source="grand_total_between_61_90"
                      label={"61 - 90 " + dayTrans}
                      textAlign="right"
                    />
                  </Labeled>
                </td>
                <td style={{ textAlign: "right" }}>
                  <Labeled>
                    <NumberField
                      source="grand_total_between_91_120"
                      label={"91 - 120 " + dayTrans}
                      textAlign="right"
                    />
                  </Labeled>
                </td>
                <td style={{ textAlign: "right" }}>
                  <Labeled>
                    <NumberField
                      source="grand_total_between_121_150"
                      label={"121 - 150 " + dayTrans}
                      textAlign="right"
                    />
                  </Labeled>
                </td>
                <td style={{ textAlign: "right" }}>
                  <Labeled>
                    <NumberField
                      source="grand_total_above_150"
                      label={"> 150 " + dayTrans}
                      textAlign="right"
                    />
                  </Labeled>
                </td>
              </tr>
            </tbody>
          </Box>
        </RecordContextProvider>
      </CardContent>
    </Card>
  );
};
export default AccountReceivableAgingAsideList;
