import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import {
  useNotify,
  SimpleForm,
  TextInput,
  NumberInput,
  useResourceContext,
  required,
  number,
  maxLength,
  useRefresh,
  useUpdate,
  Edit,
  minLength,
  minValue,
  BooleanInput,
  AutocompleteInput,
  useTranslate,
  ReferenceInput,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import DisabledInput from "../common/DisabledInput";
// import DependOnAutocompleteInput from "../../common/DependOnAutocompleteInput";
import {
  OptionRendererKind,
  filterToQueryKind,
  inputTextKind,
  matchSuggestionKind,
} from "../../common/OptionRendererKind";
import MaxStock from "../common/MaxStock";
import WarehouseReference from "../common/WarehouseReference";

const greaterThan = (value, allValues) => {
  if (value < allValues.min_stock) {
    return "Must be greater than " + allValues.min_stock;
  }
  return undefined;
};
const lessThan = (value, allValues) => {
  if (value > allValues.max_stock) {
    return "Must be less than " + allValues.max_stock;
  }
  return undefined;
};

const validateWarehouse = [required(), minLength(36), maxLength(36)];
const validateKind = [required(), minLength(36), maxLength(36)];
const validateMinStock = [number(), minValue(0), lessThan];
const validateMaxStock = [number(), minValue(0), greaterThan];
const validateActive = [required()];
const validateNote = [maxLength(125)];

export default function ProductEditDialog({ open, setOpen, recordId }) {
  const [update] = useUpdate();
  const notify = useNotify();
  const resource = useResourceContext(); // cuman nama resource
  const refresh = useRefresh();
  const t = useTranslate();

  const save = useCallback(
    async (values) => {
      try {
        await update(
          resource,
          { id: values.id, data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.updated", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [update]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false}>
      <Edit
        title={" "}
        id={recordId}
        actions={false}
        sx={{ "& .RaEdit-main": { mt: 0 } }}
      >
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns callback={handleClose} />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>{t("mine.dialog.edit_product")}</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <TextInput disabled source="id" sx={{ display: "none" }} />

            <DisabledInput />

            <ReferenceInput
              source="kind_id"
              reference="kinds"
              perPage={10}
              sort={{ field: "kinds.created_at", order: "ASC" }}
              // filter={{ all: "1102" }}
            >
              <AutocompleteInput
                label="mine.label.kind"
                variant="outlined"
                fullWidth
                sx={{ display: "inline-flex" }}
                validate={validateKind}
                optionValue="id"
                // optionText="code"
                optionText={<OptionRendererKind />}
                inputText={inputTextKind}
                matchSuggestion={matchSuggestionKind}
                filterToQuery={filterToQueryKind}
              />
            </ReferenceInput>

            <WarehouseReference
              variant="outlined"
              source="warehouse_id"
              label="mine.label.warehouse"
              fullWidth
              validate={validateWarehouse}
              reference="warehouses"
              orderBy="created_at"
              searchBy="name"
            />

            <Grid
              container
              spacing={2}
              sx={{
                "& .MuiGrid-item": { pt: 0 },
                mt: 0,
              }}
            >
              <Grid item xs={12} lg={6}>
                <NumberInput
                  variant="outlined"
                  source="min_stock"
                  fullWidth
                  validate={validateMinStock}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <MaxStock
                  variant="outlined"
                  source="max_stock"
                  fullWidth
                  validate={validateMaxStock}
                  dependOn="min_stock"
                />
              </Grid>
            </Grid>

            <TextInput
              variant="outlined"
              source="note"
              label="mine.label.note"
              fullWidth
              multiline
              minRows={3}
              maxRows={3}
              validate={validateNote}
            />

            <BooleanInput
              variant="outlined"
              source="active"
              fullWidth
              isRequired
              validate={validateActive}
              defaultValue={true}
            />
          </DialogContent>
        </SimpleForm>
      </Edit>
    </Dialog>
  );
}
