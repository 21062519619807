export function formatImage(value) {
  if (value instanceof Object) {
    return {
      src: value.src,
      title: value.title,
      size: value.rawFile.size,
    };
  }
  return value;
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("id-ID", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

export const upperCaseFirstLetter = (words) => {
  return words
    .split(" ")
    .map((w) => w[0].toUpperCase() + w.substr(1))
    .join(" ");
};

export const toSnakeCase = (words) => {
  return words.toLowerCase().replace(/\W/g, "_");
};

export const snakeCaseToNormal = (words) => {
  return words.replace(/[_]/g, " ");
};

// Create our number formatter.
export function toCurrency(withPrefix) {
  const prefix = withPrefix
    ? {
        style: "currency",
        currency: "USD",
      }
    : {};
  const formatter = new Intl.NumberFormat(
    "en-US",
    // "id-ID",
    {
      ...prefix,
      //   currencyDisplay: "code",
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    }
  );
  return formatter;
}

export const queryObjectToUrl = (qObj, prefix) => {
  const yy = Object.keys(qObj).map((str) => {
    return str + "=" + qObj[str];
  });
  if (yy.length) {
    return (prefix ? prefix : "") + yy.join("&");
  }
  return prefix ? prefix.replace("?", "") : "";
};

export function toCurrencyAccounting(number, maximumFractionDigits = 2) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: maximumFractionDigits,
    currencySign: "accounting",
  })
    .formatToParts(number)
    .filter(
      (x) =>
        x.type !== "currency" &&
        (x.type !== "literal" || x.value.trim().length !== 0)
    )
    .map((x) => {
      switch (x.type) {
        case "group":
          return ".";
        case "decimal":
          return ",";
        default:
          return x.value;
      }
    })
    .join("");
}

// module.exports = { formatImage };
