import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DeleteButton,
  NumberField,
  BulkDeleteWithConfirmButton,
  useResourceContext,
  useTheme,
  useTranslate,
  usePermissions,
} from "react-admin";
import CustomerDebtMaxDayAddDialog from "../../components/customerDebtMaxDays/create/CustomerDebtMaxDayAddDialog";
import CustomerDebtMaxDayEditDialog from "../../components/customerDebtMaxDays/edit/CustomerDebtMaxDayEditDialog";
import TableListActions from "../../components/tableListActions/TableListActions";
import ConfirmBtn from "../../components/confirmBtn/ConfirmBtn";
import EditBtn from "../../components/editBtn/EditBtn";
import DoneIcon from "@mui/icons-material/Done";
import PrintBtn from "../../components/printBtn/PrintBtn";
import customerDebtMaxDayFilterList from "../../components/customerDebtMaxDays/filter/customerDebtMaxDayFilterList";

const Aside = () => {
  const t = useTranslate();
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <h5></h5>
    </div>
  );
};

const CustomerDebtMaxDayList = () => {
  const resource = useResourceContext();
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const [showAside, setShowAside] = useState(false);
  const [theme] = useTheme("light");

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => (e) => {
    e.stopPropagation();
    setRecordId(recId);
    setOpenEdit(true);
  };
  const rowClickHandler = (id, basePath, record) => {
    setShowAside((prev) => !prev);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        aside={showAside ? <Aside /> : null}
        actions={
          <TableListActions
            createHandler={handleClickOpenAdd}
            exporter={false}
          />
        }
        filters={customerDebtMaxDayFilterList(permissions)}
        queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
          rowClick={rowClickHandler}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <NumberField source="total_day" label="mine.label.total_day" />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              render={(record) => (
                <>
                  <EditBtn
                    editHandler={handleClickOpenEdit(record.id)}
                    label=""
                    variant="outlined"
                    sx={{
                      width: "fit-content",
                      mr: "10px",
                      "& > .MuiButton-startIcon": {
                        mr: 0,
                      },
                    }}
                  />
                  <DeleteButton
                    label=""
                    variant="outlined"
                    sx={{
                      "& > .MuiButton-startIcon": {
                        mr: 0,
                      },
                    }}
                  />
                </>
              )}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    [showAside, theme]
  );
  return (
    <>
      {list}
      <CustomerDebtMaxDayAddDialog open={openAdd} setOpen={setOpenAdd} />
      <CustomerDebtMaxDayEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
    </>
  );
};

export default CustomerDebtMaxDayList;
