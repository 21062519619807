import { ArrayInput, FormDataConsumer, SimpleFormIterator } from "react-admin";
import Inputs from "./Inputs";

export default function CustomerDebtPayItemForm() {
  return (
    <ArrayInput
      source="customer_debt_correction_items"
      defaultValue={[
        {
          sale_id: "",
          "sale.date": "", // disabled
          "sale.customer_debt.due_date": "", // disabled
          "sale.customer_debt.remain": "", // disabled
          amount: "",
        },
      ]}
    >
      <SimpleFormIterator
        disableReordering
        getItemLabel={(index) => `#${index + 1}`}
        fullWidth={true}
        sx={{
          "& .RaSimpleFormIterator-line": {
            border: "none",
          },
        }}
      >
        <FormDataConsumer>{Inputs}</FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
}
