import {
  useRecordContext,
  RecordContextProvider,
  NumberField,
  useTranslate,
  DateField,
} from "react-admin";
import Box from "@mui/material/Box";
import { Virtuoso } from "react-virtuoso";

const AccountReceivableHistoryExpandList = () => {
  const record = useRecordContext();
  const t = useTranslate();

  const day = t("mine.label.day");

  const len = record.customer_debts.length - 1;

  return (
    <Virtuoso
      style={
        {
          // height: "calc(100% + 60px)",
          // margin: "0 50px",
          // marginTop: "30px",
          // width: "100%",
          // fontSize: "smaller",
          // borderCollapse: "separate",
          // "& th, td": {
          //   padding: "6px 8px",
          //   border: "1px solid #dddddd",
          // },
        }
      }
      data={record.customer_debts}
      useWindowScroll
      itemContent={(index, item) => {
        return (
          <Box
            sx={{
              px: 5,
              pt: index === 0 ? 4 : 2,
              pb: len === index ? 4 : 0, // hanya kasih padding untuk element terakhir
            }}
          >
            <Box
              sx={{
                backgroundClip: "padding-box",
                boxShadow: (theme) => {
                  return theme.components.MuiPaper.styleOverrides.elevation1
                    .boxShadow;
                },
                borderRadius: "4px",
                bgcolor: "background.paper",
              }}
            >
              <Box
                component="table"
                sx={{
                  // pb: '30px',
                  // height: `${user.size}px`,
                  width: "100%",
                  fontSize: "smaller",
                  borderCollapse: "collapse",
                  "& th, td": {
                    padding: "6px 8px",
                    border: "1px solid #dddddd",
                  },
                }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "12.5%" }}>{t("mine.label.code")}</th>
                    <th style={{ width: "12.5%" }}>
                      {t("mine.label.code")} PCM
                    </th>
                    <th style={{ width: "12.5%" }}>{t("mine.label.date")}</th>
                    <th style={{ width: "12.5%" }}>{t("mine.label.day")}</th>
                    <th style={{ textAlign: "right", width: "12.5%" }}>PCM</th>
                    <th style={{ textAlign: "right", width: "12.5%" }}>
                      {t("mine.label.payment")}
                    </th>
                    <th style={{ textAlign: "right", width: "12.5%" }}>
                      {t("mine.label.correction")}
                    </th>
                    <th style={{ textAlign: "right", width: "12.5%" }}>
                      {t("mine.label.remain")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {item.customer_debt_items.map((cdi, idx) => (
                    <tr key={idx}>
                      <td>{cdi.code}</td>
                      <td>{cdi.pcm_code ? cdi.pcm_code : "-"}</td>
                      <td>
                        <RecordContextProvider value={cdi}>
                          <DateField
                            source="date"
                            options={{
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                            }}
                            sx={{
                              fontSize: "inherit",
                            }}
                          />
                        </RecordContextProvider>
                      </td>
                      <td>{cdi.days ? cdi.days + " " + day : ""}</td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={cdi}>
                          <NumberField
                            source="pcm"
                            sx={{
                              fontSize: "inherit",
                            }}
                          />
                        </RecordContextProvider>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={cdi}>
                          <NumberField
                            source="payment"
                            sx={{
                              fontSize: "inherit",
                            }}
                          />
                        </RecordContextProvider>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={cdi}>
                          <NumberField
                            source="correction"
                            sx={{
                              fontSize: "inherit",
                            }}
                          />
                        </RecordContextProvider>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={cdi}>
                          <NumberField
                            source="remaining_debt"
                            sx={{
                              fontSize: "inherit",
                            }}
                          />
                        </RecordContextProvider>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={4} style={{ fontWeight: "bold" }}>
                      TOTAL
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="sub_total_pcm"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: "bold",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="sub_total_payment"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: "bold",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="sub_total_correction"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: "bold",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="sub_total_remaining_debt"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: "bold",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                  </tr>
                </tfoot>
              </Box>
            </Box>
          </Box>
        );
      }}
    />
  );
};

export default AccountReceivableHistoryExpandList;
