import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const purchaseReturnCardFilterList = (permissions) => {
  const defaultFilter = [
    // <TextInput
    //   source="all"
    //   label="mine.filter.search_all"
    //   alwaysOn
    //   resettable
    //   variant="outlined"
    //   InputProps={{
    //     endAdornment: (
    //       <InputAdornment position="end">
    //         <SearchIcon />
    //       </InputAdornment>
    //     ),
    //   }}
    // />,
    // <TextInput
    //   alwaysOn
    //   source="code"
    //   label="mine.label.code"
    //   resettable
    //   variant="outlined"
    // />,
    <DateInput
      alwaysOn
      source="start_date"
      label="mine.label.start_date"
      variant="outlined"
    />,
    <DateInput
      alwaysOn
      source="end_date"
      label="mine.label.end_date"
      variant="outlined"
    />,
    <ReferenceInput alwaysOn source="supplier_id" reference="suppliers">
      <SelectInput
        resettable
        optionText="name"
        variant="outlined"
        label="mine.label.supplier"
      />
    </ReferenceInput>,
    <ReferenceInput alwaysOn source="quality_id" reference="qualities">
      <AutocompleteInput
        label="mine.label.quality"
        variant="outlined"
        fullWidth
        optionValue="id"
        optionText="name"
        filterToQuery={(searchText) => ({
          name: searchText,
        })}
      />
    </ReferenceInput>,
    <ReferenceInput alwaysOn source="size_id" reference="sizes">
      <AutocompleteInput
        label="mine.label.size"
        variant="outlined"
        fullWidth
        optionValue="id"
        optionText="name"
        filterToQuery={(searchText) => ({
          name: searchText,
        })}
      />
    </ReferenceInput>,
    // <TextInput
    //   alwaysOn
    //   source="note"
    //   label="mine.label.note"
    //   resettable
    //   variant="outlined"
    // />,
  ];
  if (permissions && permissions.update_company) {
    defaultFilter.splice(
      1,
      0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        sort={{ field: "companies.alias", order: "ASC" }}
      >
        <AutocompleteInput
          resettable
          optionText="alias"
          variant="outlined"
          label="mine.label.company"
        />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};

export default purchaseReturnCardFilterList;
