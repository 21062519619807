import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DateField,
  BooleanField,
  NumberField,
  BulkDeleteWithConfirmButton,
  useResourceContext,
  useTheme,
  usePermissions,
} from "react-admin";
import JournalAddDialog from "../../components/journals/create/JournalAddDialog";
import JournalEditDialog from "../../components/journals/edit/JournalEditDialog";
import journalFilterList from "../../components/journals/filter/journalFilterList";
import TableListActionsWithUpload from "../../components/tableListActions/TableListActionsWithUpload";
import JournalExpandList from "../../components/journals/expand/JournalExpandList";
import EditBtn from "../../components/editBtn/EditBtn";
import ConfirmBtn from "../../components/confirmBtn/ConfirmBtn";
import DoneIcon from "@mui/icons-material/Done";
import JournalUploadDialog from "../../components/journals/upload/JournalUploadDialog";
import DeliveryNote from "../../components/print/DeliveryNote";

const JournalList = () => {
  const resource = useResourceContext();
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const [theme] = useTheme("light");

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        title="mine.list_title.journal"
        empty={false}
        actions={
          <TableListActionsWithUpload
            createHandler={handleClickOpenAdd}
            uploadHandler={handleClickOpenUpload}
            exporter={false}
          />
        }
        filters={journalFilterList(permissions)}
        queryOptions={{ meta: { sort: ["journals.code DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          // FIXME: semua datagrid perlu pake custom bulk action ini
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
          expand={<JournalExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField source="company.alias" label="mine.tbl_header.company" />
          ) : null}
          <TextField source="code" label="mine.tbl_header.code" />
          <DateField source="date" label="mine.tbl_header.date" />
          {/* <FunctionField
            source="salesman.name"
            label="Salesman"
            render={(record) => {
              return record.salesman_id
                ? record.salesman.name + " (" + record.salesman.code + ")"
                : "-";
            }}
          /> */}
          <NumberField source="total" />
          <TextField source="note" label="mine.tbl_header.note" />
          <BooleanField
            source="approved"
            label="mine.tbl_header.approved"
            textAlign="center"
          />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              render={(record) => {
                return record.approved ? (
                  <DeliveryNote record={record} tabTitle="Print Bukti Pengeluaran Kas/Bank" />
                ) : (
                  <>
                    <EditBtn
                      editHandler={handleClickOpenEdit(record.id)}
                      sx={{ mr: 1 }}
                    />
                    <ConfirmBtn
                      title="mine.message.approve"
                      content="mine.message.approve_content"
                      label="Approve"
                      resource={resource + "/approve"}
                      startIcon={<DoneIcon />}
                    />
                  </>
                );
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    [theme]
  );
  return (
    <>
      {list}
      <JournalAddDialog open={openAdd} setOpen={setOpenAdd} />
      <JournalEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
      {openUpload ? (
        <JournalUploadDialog open={openUpload} setOpen={setOpenUpload} />
      ) : null}
    </>
  );
};

export default JournalList;
