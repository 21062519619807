import { useMemo } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  useTheme,
  usePermissions,
  NumberField,
} from "react-admin";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import AccountReceivablePcmAgeExpandList from "../../components/accountReceivablePcmAges/expand/AccountReceivablePcmAgeExpandList";
import accountReceivablePcmAgeFilterList from "../../components/accountReceivablePcmAges/filter/accountReceivablePcmAgeFilterList";
import MyDatagridBody from "../../components/common/MyDatagridBody";
import AccountReceivablePcmAgeFooterList from "../../components/accountReceivablePcmAges/footer/AccountReceivablePcmAgeFooterList";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const AccountReceivablePcmAgeList = () => {
  const { permissions } = usePermissions();
  const [theme] = useTheme("light");

  const list = useMemo(
    () => (
      <List
        title="mine.list_title.account_receivable_pcm_age"
        empty={false}
        actions={<DownloadAction />}
        filters={accountReceivablePcmAgeFilterList}
        sort={{ field: "customers.code", order: "ASC" }}
        filterDefaultValues={{
          start_date: firstDate,
          end_date: lastDate,
          cut_off: lastDate,
        }}
        // queryOptions={{ meta: { sort: ["customers.created_at ASC"] } }} // overide default sort
      >
        <DatagridConfigurable
          body={
            <MyDatagridBody footer={<AccountReceivablePcmAgeFooterList />} />
          }
          bulkActionButtons={false}
          expand={<AccountReceivablePcmAgeExpandList />}
          isRowExpandable={(row) => row.checks}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <TextField source="code" label="mine.label.customer_code" />
          <TextField source="name" label="mine.label.customer_name" />
          <NumberField source="loan_tenor" />
          <NumberField source="subtotal" />
        </DatagridConfigurable>
      </List>
    ),
    [theme]
  );
  return list;
};

export default AccountReceivablePcmAgeList;
