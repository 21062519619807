import { useMemo, useState } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  useTheme,
  usePermissions,
  NumberField,
} from "react-admin";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import AccountReceivablePcmCardExpandList from "../../components/accountReceivablePcmCards/expand/AccountReceivablePcmCardExpandList";
import accountReceivablePcmCardFilterList from "../../components/accountReceivablePcmCards/filter/accountReceivablePcmCardFilterList";
import MyDatagridBody from "../../components/common/MyDatagridBody";
import AccountReceivablePcmCardFooterList from "../../components/accountReceivablePcmCards/footer/AccountReceivablePcmCardFooterList";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const AccountReceivablePcmCardList = () => {
  const { permissions } = usePermissions();
  const [theme] = useTheme("light");

  const list = useMemo(
    () => (
      <List
        title="mine.list_title.account_receivable_pcm_card"
        empty={false}
        actions={<DownloadAction />}
        filters={accountReceivablePcmCardFilterList}
        sort={{ field: "customers.code", order: "ASC" }}
        filterDefaultValues={{
          start_date: firstDate,
          end_date: lastDate,
        }}
        // queryOptions={{ meta: { sort: ["customers.created_at ASC"] } }} // overide default sort
      >
        <DatagridConfigurable
          body={<MyDatagridBody footer={<AccountReceivablePcmCardFooterList />} />}
          bulkActionButtons={false}
          expand={<AccountReceivablePcmCardExpandList />}
          isRowExpandable={(row) => row.checks}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <TextField source="code" label="mine.label.customer_code" />
          <TextField source="name" label="mine.label.customer_name" />
          <NumberField source="subtotal" />
        </DatagridConfigurable>
      </List>
    ),
    [theme]
  );
  return list;
};

export default AccountReceivablePcmCardList;
