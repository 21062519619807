import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DateField,
  DeleteButton,
  BulkDeleteWithConfirmButton,
  useTheme,
  usePermissions,
} from "react-admin";
import PurchaseOrderAddDialog from "../../components/purchaseOrders/create/PurchaseOrderAddDialog";
import PurchaseOrderEditDialog from "../../components/purchaseOrders/edit/PurchaseOrderEditDialog";
import purchaseOrderFilterList from "../../components/purchaseOrders/filter/purchaseOrderFilterList";
import PurchaseOrderExpandList from "../../components/purchaseOrders/expand/PurchaseOrderExpandList";
import PurchaseOrderUploadDialog from "../../components/purchaseOrders/upload/PurchaseOrderUploadDialog";
import TableListActionsWithUpload from "../../components/tableListActions/TableListActionsWithUpload";

const PurchaseOrderList = () => {
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const [theme] = useTheme("light");

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };

  const list = useMemo(
    () => (
      <List
        title="mine.list_title.purchase_order"
        empty={false}
        actions={
          <TableListActionsWithUpload
            createHandler={handleClickOpenAdd}
            uploadHandler={handleClickOpenUpload}
            exporter={false}
          />
        }
        filters={purchaseOrderFilterList(permissions)}
        queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
          expand={<PurchaseOrderExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <TextField source="code" label="mine.label.code" />
          <DateField source="date" label="mine.label.date" />
          <TextField label="Supplier" source="supplier.name" />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return <DeleteButton />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    [theme]
  );
  return (
    <>
      {list}
      <PurchaseOrderAddDialog open={openAdd} setOpen={setOpenAdd} />
      <PurchaseOrderEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
      {openUpload ? (
        <PurchaseOrderUploadDialog open={openUpload} setOpen={setOpenUpload} />
      ) : null}
    </>
  );
};

export default PurchaseOrderList;
