import {
  useRecordContext,
  RecordContextProvider,
  TabbedShowLayout,
  useTranslate,
  FunctionField,
  NumberField,
  useThemesContext,
} from "react-admin";
import Box from "@mui/material/Box";

const TransportExpandList = () => {
  const record = useRecordContext();
  const t = useTranslate();
  return (
    <TabbedShowLayout
      syncWithLocation={false}
      sx={{
        m: 4,
        backgroundClip: "padding-box",
        boxShadow: (theme) => {
          return theme.components.MuiPaper.styleOverrides.elevation1.boxShadow;
        },
        borderRadius: "4px",
        bgcolor: "background.paper", // "#fff",
        "& .RaTabbedShowLayout-content": {
          p: 0,
        },
        "& .RaLabeled-label": {
          display: "none",
        },
      }}
    >
      <TabbedShowLayout.Tab label="mine.label.cost">
        <Box
          component="table"
          sx={{
            width: "100%",
            // bgcolor: "rgba(0,0,0,.04)",
            fontSize: "smaller",
            borderCollapse: "collapse",
            "& th, td": {
              padding: "6px 8px",
              border: "1px solid #dddddd",
            },
          }}
        >
          <thead>
            <tr>
              <th>{t("mine.label.size")}</th>
              <th style={{ textAlign: "right" }}>{t("mine.label.cost")}</th>
              <th>{t("mine.label.cost_type")}</th>
            </tr>
          </thead>
          <tbody>
            {record.transport_costs.map((item, idx) => {
              return (
                <tr key={item.id}>
                  <td>{item.size ? item.size.name : "-"}</td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="cost"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td>
                    <RecordContextProvider value={item}>
                      <FunctionField
                        render={(record) => {
                          switch (record.cost_type) {
                            case 1:
                              return "HARGA / MOBIL";
                            case 2:
                              return "HARGA / KG";
                            case 3:
                              return "HARGA / BOX";
                            default:
                              return "-";
                          }
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Box>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  );
};

export default TransportExpandList;
