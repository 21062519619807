import { forwardRef, Fragment } from "react";
import Box from "@mui/material/Box";
import classes from "./PrintContent.module.css";
import Typography from "@mui/material/Typography";
import {
  DateField,
  NumberField,
  RecordContextProvider,
  useTranslate,
  // usePermissions,
} from "react-admin";
import splitArrayToChunks from "../../../utils/splitArrayToChunks";

const PrintContent = forwardRef((props, ref) => {
  // eslint-disable-line max-len
  // const { permissions, isLoading } = usePermissions();
  const t = useTranslate();

  let checkStatus = "";
  if (props.record.type === 1) {
    checkStatus = t("mine.label.stocktake");
  } else if (props.record.type === 2) {
    checkStatus = t("mine.label.sample");
  }

  var total_sent = { total: 0 };

  return (
    <Box
      component="div"
      sx={{
        display: "none",
      }}
    >
      <Box ref={ref}>
        <style type="text/css" media="print">
          {`@page {
              width: 215.9mm;
              height: 139.7mm;
              size: 8.5in 5.5in portrait;
              margin: 0;
            }
            html, body {
              width: 215.9mm;
              height: 100%;
            }
            .page-break {
              display: block;
              page-break-after: always;
            }
          `}
        </style>
        {splitArrayToChunks(
          props.record.mutation_inventory_write_off_items,
          3
        ).map((arr, i, ori) => {
          // TODO semua print content strukturnya perlu ikutin ini
          return (
            <Fragment key={i}>
              <Box
                sx={
                  i > 0
                    ? {
                        mt: 2,
                        fontSize: "12px",
                        py: 2,
                        px: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: "calc(100vh - 40px)",
                        fontFamily: "'Source Code Pro', monospace",
                      }
                    : {
                        fontSize: "12px",
                        py: 2,
                        px: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: "calc(100vh - 40px)",
                        fontFamily: "'Source Code Pro', monospace",
                      }
                }
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      fontFamily: "inherit",
                    }}
                  >
                    {props.record.company.name}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    mb: 2,
                    textTransform: "uppercase",
                    borderBottom: "1px solid #000",
                    textAlign: "center",
                    fontFamily: "inherit",
                  }}
                >
                  slip opname kredit barang
                </Typography>
                <table className={classes.headerSj}>
                  <thead>
                    <tr>
                      <th style={{ width: "10%", verticalAlign: "top" }}>
                        No. Bukti
                      </th>
                      <th style={{ width: "1px", verticalAlign: "top" }}>:</th>
                      <th style={{ verticalAlign: "top" }}>
                        {props.record.code}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: "10%", verticalAlign: "top" }}>
                        Tanggal
                      </th>
                      <th style={{ verticalAlign: "top" }}>:</th>
                      <th style={{ verticalAlign: "top" }}>
                        <RecordContextProvider value={props.record}>
                          <DateField
                            source="date"
                            sx={{
                              fontWeight: "inherit",
                              fontSize: "12px",
                              fontFamily: "inherit",
                            }}
                          ></DateField>
                        </RecordContextProvider>
                      </th>
                    </tr>
                    <tr>
                      <th style={{ verticalAlign: "top" }}>Tipe</th>
                      <th style={{ verticalAlign: "top" }}>:</th>
                      <th style={{ verticalAlign: "top" }}>{checkStatus}</th>
                    </tr>
                  </thead>
                </table>
                <Typography
                  variant="caption"
                  sx={{
                    mt: 1,
                    mb: 1,
                    fontFamily: "inherit",
                  }}
                >
                  Keterangan: {props.record.note}
                </Typography>
                <table className={classes.tableSj}>
                  <thead>
                    <tr>
                      <th>Kode Barang</th>
                      <th>Nama Barang</th>
                      <th style={{ textAlign: "right" }}>Kuantitas</th>
                      <th>Lokasi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arr.map((obj, idx) => {
                      total_sent.total += +obj.quantity;
                      return (
                        <tr key={obj.id}>
                          <td>{obj.product.code}</td>
                          <td>{obj.product.name}</td>
                          <td style={{ textAlign: "right" }}>
                            <RecordContextProvider value={obj}>
                              <NumberField
                                source="quantity"
                                sx={{
                                  fontWeight: "inherit",
                                  fontSize: "12px",
                                  fontFamily: "inherit",
                                }}
                              ></NumberField>
                            </RecordContextProvider>
                          </td>
                          <td>{obj.warehouse.name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        colSpan={2}
                        style={{
                          textAlign: "right",
                          verticalAlign: "baseline",
                          fontWeight: "bold",
                          borderRight: "none",
                        }}
                      >
                        Total:
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={total_sent}>
                          <NumberField
                            source="total"
                            sx={{
                              fontWeight: "inherit",
                              fontSize: "12px",
                              fontFamily: "inherit",
                            }}
                          ></NumberField>
                        </RecordContextProvider>
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
                <table
                  style={{ marginTop: "auto" }}
                  className={classes.tableACC}
                >
                  <thead>
                    <tr>
                      <th colSpan={2}>Mengetahui</th>
                      <th>Pemeriksa</th>
                      <th colSpan={3}>Pelaksana</th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          textTransform: "uppercase",
                          width: "16.66%",
                        }}
                      >
                        M. Keuangan
                      </th>
                      <th
                        style={{
                          textTransform: "uppercase",
                          width: "16.66%",
                        }}
                      >
                        M. Sales
                      </th>
                      <th
                        style={{
                          textTransform: "uppercase",
                          width: "16.66%",
                        }}
                      >
                        Auditor
                      </th>
                      <th
                        style={{
                          textTransform: "uppercase",
                          width: "16.66%",
                        }}
                      >
                        K. Depo
                      </th>
                      <th
                        style={{
                          textTransform: "uppercase",
                          width: "16.66%",
                        }}
                      >
                        Akunting Depo
                      </th>
                      <th
                        style={{
                          textTransform: "uppercase",
                          width: "16.66%",
                        }}
                      >
                        K. Gudang
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={{ paddingTop: "60px" }}></td>
                    </tr>
                  </tbody>
                </table>
              </Box>
              <div className="page-break" />
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
});
export default PrintContent;
