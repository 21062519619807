import { useMemo } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  NumberField,
  useTheme,
  usePermissions,
  useTranslate,
} from "react-admin";
import productRankingFilterList from "../../components/productRankings/filter/productRankingFilterList";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import ProductRankingExpandList from "../../components/productRankings/expand/ProductRankingExpandList";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const ProductRankingList = (props) => {
  const { permissions } = usePermissions();
  const [theme] = useTheme("light");
  const t = useTranslate();

  const list = useMemo(() => {
    let filterDefaultValues = {
      start_date: firstDate,
      end_date: lastDate,
    };

    return (
      <List
        title="mine.list_title.product_ranking"
        empty={false}
        actions={<DownloadAction field="id" order="DESC" />}
        filters={productRankingFilterList(permissions)}
        filterDefaultValues={filterDefaultValues}
        queryOptions={{ meta: { sort: ["total_profit DESC"] } }} // total_profit adalah kolom hasil SUM(), bukan kolom di database
        sx={{
          flexDirection: "column",
        }}
      >
        <DatagridConfigurable
          bulkActionButtons={false}
          rowSx={(record, index) => {
            // redupkan text SJ gratis
            if (record.code.endsWith("P")) {
              return {
                "& .RaDatagrid-rowCell": {
                  color: theme !== "dark" ? "grey.500" : "rgba(255,255,255,.4)",
                },
              };
            }
          }}
          expand={<ProductRankingExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <TextField source="code" label="mine.label.product_code" />
          <NumberField source="total_quantity_sold" label="Total Quantity" />
          <NumberField
            source="ranking_quantity_sold"
            label="Ranking Quantity"
          />
          <NumberField
            source="total_product"
            label={"Total " + t("mine.expand_menu.product")}
          />
          <NumberField source="total_profit" label="Total Profit" />
          <NumberField source="ranking_total_profit" label="Ranking Profit" />
          <NumberField
            source="total_average_profit"
            label={"Total " + t("mine.label.profit_average")}
          />
          <NumberField
            source="remaining_stock"
            label="mine.label.remaining_stock"
          />
        </DatagridConfigurable>
      </List>
    );
  }, [theme]);
  return list;
};

export default ProductRankingList;
