import { useMemo, useState } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  useTheme,
  usePermissions,
  NumberField,
} from "react-admin";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import AccountReceivablePcmExpandList from "../../components/accountReceivablePcms/expand/AccountReceivablePcmExpandList";
import accountReceivablePcmFilterList from "../../components/accountReceivablePcms/filter/accountReceivablePcmFilterList";

const Aside = () => {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <h5></h5>
    </div>
  );
};

const AccountReceivablePcmList = () => {
  const { permissions } = usePermissions();
  const [showAside, setShowAside] = useState(false);
  const [theme] = useTheme("light");

  const rowClickHandler = (id, basePath, record) => {
    // console.log("Lets", id, basePath, record);
    setShowAside((prev) => !prev);
  };
  const list = useMemo(
    () => (
      <List
        title="mine.list_title.account_receivable_pcm"
        empty={false}
        aside={showAside ? <Aside /> : null}
        actions={<DownloadAction />}
        filters={accountReceivablePcmFilterList(permissions)}
        sort={{ field: "customers.code", order: "ASC" }}
        // queryOptions={{ meta: { sort: ["customers.created_at ASC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={false}
          rowClick={rowClickHandler}
          expand={<AccountReceivablePcmExpandList />}
          isRowExpandable={(row) =>
            row.customer_debt_payments || row.customer_debts
          }
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <TextField source="code" label="mine.label.customer_code" />
          <TextField source="name" label="mine.label.customer_name" />
          <NumberField source="loan_limit" label="mine.label.loan_limit" />
          <NumberField
            source="total_ar"
            label="resources.account_receivables.name"
          />
          <NumberField source="total_pcm" label="PCM" />
          <NumberField
            source="total_ar_net"
            label="mine.label.account_receivable_net"
          />
        </DatagridConfigurable>
      </List>
    ),
    [showAside, theme]
  );
  return list;
};

export default AccountReceivablePcmList;
