import * as React from "react";
import { cloneElement, memo } from "react";
import { TableBody } from "@mui/material";
import clsx from "clsx";

import {
  DatagridRow,
  PureDatagridRow,
  DatagridClasses,
  RecordContextProvider,
} from "react-admin";

const MyDatagridBody = React.forwardRef(
  (
    {
      children,
      className,
      data = defaultData,
      expand,
      hasBulkActions = false,
      hover,
      onToggleItem,
      resource,
      row = defaultChildren,
      rowClick,
      rowSx,
      rowStyle,
      selectedIds,
      isRowSelectable,
      footer,
      ...rest
    },
    ref
  ) => {
    return (
      <>
        <TableBody
          ref={ref}
          className={clsx("datagrid-body", className, DatagridClasses.tbody)}
          {...rest}
        >
          {data.map((record, rowIndex) => (
            <RecordContextProvider
              value={record}
              key={record.id ?? `row${rowIndex}`}
            >
              {cloneElement(
                row,
                {
                  className: clsx(DatagridClasses.row, {
                    [DatagridClasses.rowEven]: rowIndex % 2 === 0,
                    [DatagridClasses.rowOdd]: rowIndex % 2 !== 0,
                  }),
                  expand,
                  hasBulkActions: hasBulkActions && !!selectedIds,
                  hover,
                  id: record.id ?? `row${rowIndex}`,
                  onToggleItem,
                  resource,
                  rowClick,
                  selectable: !isRowSelectable || isRowSelectable(record),
                  selected: selectedIds?.includes(record.id),
                  sx: rowSx?.(record, rowIndex),
                  style: rowStyle?.(record, rowIndex),
                },
                children
              )}
            </RecordContextProvider>
          ))}
        </TableBody>
        {/* <!-- here starts the custom part --> */ footer}
      </>
    );
  }
);

const defaultChildren = <DatagridRow />;

const defaultData = [];

// trick Material UI Table into thinking this is one of the child type it supports
// @ts-ignore
MyDatagridBody.muiName = "TableBody";

export const PureDatagridBody = memo((props) => (
  <MyDatagridBody row={<PureDatagridRow />} {...props} />
));

// trick Material UI Table into thinking this is one of the child type it supports
// @ts-ignore
PureDatagridBody.muiName = "TableBody";

export default MyDatagridBody;
