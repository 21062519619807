import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DateField,
  BooleanField,
  NumberField,
  BulkDeleteWithConfirmButton,
  FunctionField,
  useTranslate,
  useResourceContext,
  RecordContextProvider,
  ChipField,
  useTheme,
  usePermissions,
} from "react-admin";
import MutationInventoryWriteOffAddDialog from "../../components/mutationInventoryWriteOffs/create/MutationInventoryWriteOffAddDialog";
import MutationInventoryWriteOffEditDialog from "../../components/mutationInventoryWriteOffs/edit/MutationInventoryWriteOffEditDialog";
import mutationInventoryWriteOffFilterList from "../../components/mutationInventoryWriteOffs/filter/mutationInventoryWriteOffFilterList";
import TableListActions from "../../components/tableListActions/TableListActions";
import MutationInventoryWriteOffExpandList from "../../components/mutationInventoryWriteOffs/expand/MutationInventoryWriteOffExpandList";
import EditBtn from "../../components/editBtn/EditBtn";
import ConfirmBtn from "../../components/confirmBtn/ConfirmBtn";
import DoneIcon from "@mui/icons-material/Done";
import PrintBtn from "../../components/printBtn/PrintBtn";
import PrintHandler from "../../components/printHandler/PrintHandler";
import PrintContent from "../../components/mutationInventoryWriteOffs/print/PrintContent";

const MutationInventoryWriteOffList = () => {
  const resource = useResourceContext();
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const [resetPrintContent, setResetPrintContent] = useState();
  const [printRecord, setPrintRecord] = useState({}); // initial value wajib object kosong untuk cek key id di dependency useeffect DeliveryNote.js
  const t = useTranslate();
  const [theme] = useTheme("light");
  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        title="mine.list_title.mutation_inventory_write_off"
        empty={false}
        actions={
          <TableListActions
            createHandler={handleClickOpenAdd}
            exporter={false}
          />
        }
        filters={mutationInventoryWriteOffFilterList(permissions)}
        queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
          expand={<MutationInventoryWriteOffExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField source="company.alias" label="mine.tbl_header.company" />
          ) : null}
          <TextField source="code" label="mine.tbl_header.code" />
          <DateField source="date" label="mine.tbl_header.date" />
          <FunctionField
            textAlign="center"
            label="mine.tbl_header.type"
            render={(record) => {
              let rec = {};
              switch (record.type) {
                case 1:
                  rec.type = t("mine.label.stocktake");
                  rec.color = "primary";
                  break;
                case 2:
                  rec.type = t("mine.label.sample");
                  rec.color = "success";
                  break;
                case 3:
                  rec.type = t("mine.label.warehouse_broke");
                  rec.color = "error";
                  break;
                default: // case 4
                  rec.type = t("mine.label.transfer_between_depo");
                  rec.color = "warning";
              }
              return (
                <RecordContextProvider value={rec}>
                  <ChipField source="type" color={rec.color} size="small" />
                </RecordContextProvider>
              );
            }}
          />
          <TextField
            source="delivery_note"
            label="mine.tbl_header.delivery_note"
            emptyText="-"
          />
          {/* <FunctionField
            label="mine.tbl_header.delivery_note"
            render={(record) =>
              record.delivery_note ? record.delivery_note : "-"
            }
          /> */}
          <FunctionField
            textAlign="right"
            label="Box"
            render={(record) => {
              let rec = { total_sent: 0 };
              for (
                let i = 0;
                i < record.mutation_inventory_write_off_items.length;
                i++
              ) {
                rec.total_sent +=
                  +record.mutation_inventory_write_off_items[i].quantity;
              }
              return (
                <RecordContextProvider value={rec}>
                  <NumberField source="total_sent" />
                </RecordContextProvider>
              );
            }}
          />
          <FunctionField
            source="total"
            textAlign="right"
            render={(record) => {
              return (
                <RecordContextProvider value={record}>
                  <NumberField
                    source="total"
                    title={
                      record.approved
                        ? ""
                        : t("mine.tooltip.total_inventory_write_off")
                    }
                  />
                </RecordContextProvider>
              );
            }}
          />

          <TextField source="note" label="mine.tbl_header.note" />
          <BooleanField
            source="approved"
            label="mine.tbl_header.approved"
            textAlign="center"
          />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              render={(record) => {
                return !record.approved ? (
                  <>
                    <EditBtn
                      editHandler={handleClickOpenEdit(record.id)}
                      sx={{ mr: 1 }}
                    />
                    <ConfirmBtn
                      title="mine.message.approve"
                      content="mine.message.approve_content"
                      label="mine.btn.approve"
                      resource={resource + "/approve"}
                      startIcon={<DoneIcon />}
                    />
                  </>
                ) : (
                  <PrintBtn
                    dialogProps={{ title: "mine.dialog.print" }}
                    recordId={record.id}
                    setPrintRecord={setPrintRecord}
                    setResetPrintContent={setResetPrintContent}
                  />
                );
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    [theme]
  );
  return (
    <>
      {list}
      <MutationInventoryWriteOffAddDialog open={openAdd} setOpen={setOpenAdd} />
      <MutationInventoryWriteOffEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
      <PrintHandler
        record={printRecord}
        resetPrintContent={resetPrintContent}
        Content={PrintContent}
      />
    </>
  );
};

export default MutationInventoryWriteOffList;
