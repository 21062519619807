import {
  useRecordContext,
  RecordContextProvider,
  NumberField,
  useTranslate,
  DateField,
  FunctionField,
  TextField,
} from "react-admin";
import Box from "@mui/material/Box";
import { toCurrencyAccounting } from "../../../utils/formatter";

const InventoryHistoryExpandList = () => {
  const record = useRecordContext();
  const t = useTranslate();

  let inventoryCounter = 0;
  for (let i = 0; i < record.prices.length; i++) {
    if (record.prices[i].inventories.length) {
      inventoryCounter += 1;
    }
  }
  if (inventoryCounter === 0) {
    return undefined;
  }

  return record.prices.length ? (
    <Box
      sx={{
        m: 5,
        backgroundClip: "padding-box",
        borderRadius: "4px",
      }}
    >
      {record.prices.map((priceItem, index) => {
        if (!priceItem.inventories.length) {
          return null;
        }

        const totalPerHPP = {
          totalInitial: 0,
          totalMoneyInitial: 0,
          totalIn: 0,
          totalMoneyIn: 0,
          totalOut: 0,
          totalMoneyOut: 0,
          totalFinal: 0,
          totalMoneyFinal: 0,
        };

        return (
          <Box
            key={priceItem.buy_price + index}
            sx={{
              position: "relative",
              mb: 3,
            }}
          >
            <Box
              component="span"
              sx={{
                py: "2px",
                px: "10px",
                backgroundImage:
                  "linear-gradient(98deg, rgb(166, 119, 253), rgb(100, 59, 177) 94%)",
                color: "#fff",
                position: "absolute",
                top: "-24px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "18px",
              }}
            >
              <RecordContextProvider value={priceItem}>
                HPP{" "}
                <NumberField
                  source="amount"
                  sx={{
                    fontSize: "14px",
                  }}
                />
              </RecordContextProvider>
            </Box>
            <Box
              sx={{
                overflow: "hidden",
                borderRadius: "8px",
                borderTopLeftRadius: 0,
                boxShadow: (theme) => {
                  return theme.components.MuiPaper.styleOverrides.elevation1
                    .boxShadow;
                },
                bgcolor: "background.paper",
              }}
            >
              <Box
                component="table"
                sx={{
                  width: "100%",
                  // bgcolor: "rgba(0,0,0,.04)",
                  fontSize: "smaller",
                  borderCollapse: "collapse",
                  "& th, td": {
                    padding: "6px 8px",
                    border: "1px solid #dddddd",
                  },
                }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "8.3%" }}>{t("mine.label.code")}</th>
                    <th style={{ width: "8.3%" }}>{t("mine.label.date")}</th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.label.initial")}
                    </th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.label.initial_value")}
                    </th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.tbl_header.total_in")}
                    </th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.label.value_in")}
                    </th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.tbl_header.total_out")}
                    </th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.label.value_out")}
                    </th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.label.final")}
                    </th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.label.final_value")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {priceItem.inventories.map((item, idx) => {
                    return (
                      <tr key={item.code + idx}>
                        <td>{item.code}</td>
                        <td>
                          <RecordContextProvider value={item}>
                            <DateField
                              source="date"
                              options={{
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              }}
                              sx={{
                                fontSize: "inherit",
                              }}
                            />
                          </RecordContextProvider>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <RecordContextProvider value={item}>
                            <NumberField
                              source="initial"
                              sx={{
                                fontSize: "inherit",
                                // color: "rgba(0,0,0,.3)",
                              }}
                            />
                          </RecordContextProvider>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <RecordContextProvider value={item}>
                            <NumberField
                              source="initial_value"
                              sx={{
                                fontSize: "inherit",
                              }}
                            />
                          </RecordContextProvider>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <RecordContextProvider value={item}>
                            <NumberField
                              source="in"
                              sx={{
                                fontSize: "inherit",
                              }}
                            />
                          </RecordContextProvider>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <RecordContextProvider value={item}>
                            <NumberField
                              source="in_value"
                              sx={{
                                fontSize: "inherit",
                              }}
                            />
                          </RecordContextProvider>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <RecordContextProvider value={item}>
                            <NumberField
                              source="out"
                              sx={{
                                fontSize: "inherit",
                              }}
                            />
                          </RecordContextProvider>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <RecordContextProvider value={item}>
                            <NumberField
                              source="out_value"
                              sx={{
                                fontSize: "inherit",
                              }}
                            />
                          </RecordContextProvider>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <FunctionField
                            source="final"
                            textAlign="right"
                            render={() => {
                              return (
                                <RecordContextProvider
                                  value={{
                                    amount: toCurrencyAccounting(item.final, 0).replaceAll(".", ","),
                                  }}
                                >
                                  <TextField
                                    source="amount"
                                    sx={{
                                      fontSize: "smaller",
                                    }}
                                    emptyText="0"
                                  />
                                </RecordContextProvider>
                              );
                            }}
                          />
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <FunctionField
                            source="final_value"
                            textAlign="right"
                            render={() => {
                              return (
                                <RecordContextProvider
                                  value={{
                                    amount: toCurrencyAccounting(
                                      item.final_value,
                                      0
                                    ).replaceAll(".", ","),
                                  }}
                                >
                                  <TextField
                                    source="amount"
                                    sx={{
                                      fontSize: "smaller",
                                    }}
                                    emptyText="0"
                                  />
                                </RecordContextProvider>
                              );
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={2} style={{ fontWeight: 700 }}>
                      Total
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={priceItem}>
                        <NumberField
                          source="subtotal_initial"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={priceItem}>
                        <NumberField
                          source="subtotal_initial_value"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={priceItem}>
                        <NumberField
                          source="subtotal_in"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    {/* <td></td> */}
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={priceItem}>
                        <NumberField
                          source="subtotal_in_value"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={priceItem}>
                        <NumberField
                          source="subtotal_out"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    {/* <td></td> */}
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={priceItem}>
                        <NumberField
                          source="subtotal_out_value"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={priceItem}>
                        <NumberField
                          source="subtotal_final"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={priceItem}>
                        <NumberField
                          source="subtotal_final_value"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                  </tr>
                </tbody>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  ) : null;
};

export default InventoryHistoryExpandList;
