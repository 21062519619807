import { TableFooter, TableRow, TableCell } from "@mui/material";
import useGrandTotal from "../../hooks/useGrandTotal";
import { NumberField, RecordContextProvider, TextField } from "react-admin";

const InventoryAgeFooterList = () => {
  const { data, isLoading, error } = useGrandTotal();

  // if (error) return <Error />;

  return (
    <RecordContextProvider value={data ? data[0] : {}}>
      <TableFooter>
        <TableRow
          sx={{
            "& td": {
              borderTop: "1px solid rgba(224, 224, 224, 1) !important",
            },
            "& .MuiTypography-root": {
              color: (theme) => theme.palette.primary.main,
              fontWeight: "bold",
            },
          }}
        >
          <TableCell colSpan={4}>
            <RecordContextProvider value={{ name: "GRAND TOTAL" }}>
              <TextField source="name" />
            </RecordContextProvider>
          </TableCell>
          <TableCell sx={{ textAlign: "right" }}>
            <NumberField source="grand_total_quantity" textAlign="right" />
          </TableCell>
          <TableCell sx={{ textAlign: "right" }}>
            <NumberField source="grand_total_cogs" textAlign="right" />
          </TableCell>
          <TableCell colSpan={2}></TableCell>
        </TableRow>
      </TableFooter>
    </RecordContextProvider>
  );
};

export default InventoryAgeFooterList;
