import { useEffect, useState } from "react";
import {
  Datagrid,
  DateField,
  NumberField,
  TextField,
  useGetList,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { useWatch, useFormContext } from "react-hook-form";

const DebtListGrid = ({ dependOn, open, isEdit }) => {
  const [firstTime, setFirstTime] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const dependOnVal = useWatch({ name: dependOn });
  const { setValue } = useFormContext();
  const record = useRecordContext();
  const t = useTranslate();
  const { data, total } = useGetList("sales/bad-debt", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "CustomerDebt.created_at", order: "ASC" },
    filter: {
      "CustomerDebt.customer_id": dependOnVal,
      ...(isEdit ? { update: "1" } : {}),
    },
  });
  // tambah attribute baru di object form setiap kali select checkbox
  useEffect(() => {
    if (firstTime) {
      setValue("bad_debts", selectedIds, { shouldDirty: false });
    } else {
      setValue("bad_debts", selectedIds, { shouldDirty: true });
    }
  }, [selectedIds.length, firstTime]);
  // reset select checkbox setiap close dialog
  useEffect(() => {
    if (!open) {
      setSelectedIds([]);
    }
  }, [open]);
  // saat edit select datagrid sesuai data yg terselect dr server
  useEffect(() => {
    if (isEdit) {
      const selected = [];
      for (let i = 0; i < record.bad_debt_items.length; i++) {
        const elm = record.bad_debt_items[i];
        selected.push(elm.sale_id);
      }
      setSelectedIds(selected);
    }
  }, [record, isEdit]);
  // saat ganti customer select perlu dibersihkan
  useEffect(() => {
    setFirstTime(false); // untuk handle aktifasi submit btn
    setSelectedIds([]);
  }, [dependOnVal]);
  return (
    <>
      <Datagrid
        data={dependOnVal ? data : undefined}
        total={total}
        isLoading={!dependOnVal}
        sort={{ field: "CustomerDebt.created_at", order: "ASC" }}
        selectedIds={selectedIds}
        optimized
        rowClick="toggleSelection"
        bulkActionButtons={<></>}
        onSelect={(ids) => {
          setFirstTime(false); // untuk handle aktifasi submit btn
          setSelectedIds(ids);
        }}
        onToggleItem={(id) => {
          setFirstTime(false); // untuk handle aktifasi submit btn
          setSelectedIds((prev) => {
            const newPrev = [...prev];
            if (newPrev.includes(id)) {
              var index = newPrev.indexOf(id);
              if (index !== -1) {
                newPrev.splice(index, 1);
              }
            } else {
              newPrev.push(id);
            }
            return newPrev;
          });
        }}
      >
        <TextField source="code" label={t("mine.tbl_header.sale_code")} />
        <NumberField
          source="customer_debt.remain"
          label={t("mine.tbl_header.remaining_debt")}
        />
        <DateField
          source="created_at"
          label={t("mine.tbl_header.transaction_date")}
        />
        <DateField
          source="customer_debt.due_date"
          label={t("mine.tbl_header.due_date")}
        />
      </Datagrid>
    </>
  );
};
export default DebtListGrid;
