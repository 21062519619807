import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
} from "react-admin";

const saleRankingFilterList = (permissions) => {
  const defaultFilter = [
    <DateInput
      alwaysOn
      source="start_date"
      label="mine.label.start_date"
      variant="outlined"
    />,
    <DateInput
      alwaysOn
      source="end_date"
      label="mine.label.end_date"
      variant="outlined"
    />,
    <ReferenceInput alwaysOn source="brand_id" reference="brands">
      <AutocompleteInput
        label="mine.label.brand"
        variant="outlined"
        fullWidth
        optionValue="id"
        optionText="name"
        filterToQuery={(searchText) => ({
          name: searchText,
        })}
      />
    </ReferenceInput>,
  ];
  if (permissions && permissions.update_company) {
    defaultFilter.splice(
      1,
      0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        sort={{ field: "companies.alias", order: "ASC" }}
      >
        <AutocompleteInput
          resettable
          optionText="alias"
          variant="outlined"
          label="mine.label.company"
        />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};
export default saleRankingFilterList;
