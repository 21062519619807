import { forwardRef, Fragment } from "react";
import Box from "@mui/material/Box";
import classes from "./PrintContent.module.css";
import Typography from "@mui/material/Typography";
import {
  DateField,
  NumberField,
  RecordContextProvider,
  // usePermissions,
} from "react-admin";
import splitArrayToChunks from "../../../utils/splitArrayToChunks";

const PrintContent = forwardRef((props, ref) => {
  // eslint-disable-line max-len
  // const { permissions, isLoading } = usePermissions();

  let totalReceived = 0;
  return (
    <Box
      component="div"
      sx={{
        display: "none",
      }}
    >
      <Box ref={ref}>
        <style type="text/css" media="print">
          {`@page {
              width: 215.9mm;
              height: 139.7mm;
              size: 8.5in 5.5in portrait;
              margin: 0;
            }
            html, body {
              width: 215.9mm;
              height: 100%;
            }
            .page-break {
              display: block;
              page-break-after: always;
            }
          `}
        </style>
        {splitArrayToChunks(props.record.purchase_items, 6).map(
          (arr, i, ori) => {
            return (
              <Fragment key={i}>
                <Box
                  sx={
                    i > 0
                      ? {
                          mt: 2,
                          fontSize: "12px",
                          py: 2,
                          px: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: "calc(100vh - 40px)",
                          fontFamily: "'Source Code Pro', monospace",
                        }
                      : {
                          fontSize: "12px",
                          py: 2,
                          px: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: "calc(100vh - 40px)",
                          fontFamily: "'Source Code Pro', monospace",
                        }
                  }
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      mb: 2,
                      textTransform: "uppercase",
                      borderBottom: "1px solid #000",
                      textAlign: "center",
                      fontFamily: "inherit",
                    }}
                  >
                    invoice pembelian
                  </Typography>
                  <table className={classes.headerSj}>
                    <thead>
                      <tr>
                        <th>No. Bukti</th>
                        <th>:</th>
                        <th>{props.record.code}</th>
                        <th style={{ width: "40%" }}></th>
                        <th>No. Order</th>
                        <th>:</th>
                        <th>
                          {
                            props.record.purchase_items[0].purchase_order_item
                              .purchase_order.code
                          }
                        </th>
                      </tr>
                      <tr>
                        <th>Tgl. Bukti</th>
                        <th>:</th>
                        <th>
                          <RecordContextProvider value={props.record}>
                            <DateField
                              source="date"
                              sx={{
                                fontWeight: "inherit",
                                fontSize: "12px",
                                fontFamily: "inherit",
                              }}
                            ></DateField>
                          </RecordContextProvider>
                        </th>
                        <th style={{ width: "40%" }}></th>
                        <th>Nama Pemasok</th>
                        <th>:</th>
                        <th>{props.record.supplier.name}</th>
                      </tr>
                    </thead>
                  </table>

                  <table
                    className={classes.tableSj}
                    style={{ marginTop: "8px" }}
                  >
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Kode Barang</th>
                        <th>Ukuran</th>
                        <th style={{ textAlign: "right" }}>Qty Pesan</th>
                        <th style={{ textAlign: "right" }}>Qty Terima</th>
                        <th style={{ textAlign: "right" }}>Harga Satuan</th>
                        <th style={{ textAlign: "right" }}>Total</th>
                        <th>Lokasi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {arr.map((obj, idx) => {
                        totalReceived += obj.received_quantity;
                        return (
                          <tr key={obj.purchase_order_item.product.id}>
                            <td style={{ textAlign: "center" }}>{idx + 1}</td>
                            <td>{obj.purchase_order_item.product.code}</td>
                            <td>
                              {obj.purchase_order_item.product.size_name +
                                " " +
                                obj.purchase_order_item.product.quality_name}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {obj.received_quantity}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {obj.received_quantity}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <RecordContextProvider
                                value={obj.purchase_order_item}
                              >
                                <NumberField
                                  source="price"
                                  sx={{
                                    fontWeight: "inherit",
                                    fontSize: "12px",
                                    fontFamily: "inherit",
                                  }}
                                ></NumberField>
                              </RecordContextProvider>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <RecordContextProvider value={obj}>
                                <NumberField
                                  source="subtotal"
                                  sx={{
                                    fontWeight: "inherit",
                                    fontSize: "12px",
                                    fontFamily: "inherit",
                                  }}
                                ></NumberField>
                              </RecordContextProvider>
                            </td>
                            <td>{obj.warehouse.name}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          colSpan={3}
                          style={{
                            textAlign: "right",
                            verticalAlign: "baseline",
                            fontWeight: "bold",
                            borderRight: "none",
                          }}
                        >
                          Total:
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            verticalAlign: "baseline",
                            fontWeight: "bold",
                            borderLeft: "none",
                          }}
                        ></td>
                        <td style={{ textAlign: "right" }}>{totalReceived}</td>
                        <td></td>
                        <td style={{ textAlign: "right" }}>
                          <RecordContextProvider value={props.record}>
                            <NumberField
                              source="total"
                              sx={{
                                fontWeight: "inherit",
                                fontSize: "12px",
                                fontFamily: "inherit",
                              }}
                            ></NumberField>
                          </RecordContextProvider>
                        </td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                  <Typography
                    variant="caption"
                    sx={{
                      mt: "auto",
                      textTransform: "capitalize",
                      fontFamily: "inherit",
                    }}
                  >
                    {"Terbilang: # " +
                      props.record.total_in_words +
                      " Rupiah #"}
                  </Typography>
                  <table
                    style={{ marginTop: "8px" }}
                    className={classes.tableACC}
                  >
                    <thead>
                      <tr>
                        <th>Mengetahui</th>
                        <th colSpan={2}>Pemeriksa</th>
                        <th>Pelaksana</th>
                      </tr>
                      <tr>
                        <th
                          style={{ textTransform: "uppercase", width: "25%" }}
                        >
                          Manager Sales
                        </th>
                        <th
                          style={{ textTransform: "uppercase", width: "25%" }}
                        >
                          Akunting
                        </th>
                        <th
                          style={{ textTransform: "uppercase", width: "25%" }}
                        >
                          Administrasi
                        </th>
                        <th
                          style={{ textTransform: "uppercase", width: "25%" }}
                        >
                          Kepala Gudang
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ padding: "40px 0" }}></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </Box>
                <div className="page-break" />
              </Fragment>
            );
          }
        )}
      </Box>
    </Box>
  );
});
export default PrintContent;
