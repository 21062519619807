import * as React from "react";
import {
  DatagridBody,
  DatagridConfigurable,
  FunctionField,
  List,
  NumberField,
  Pagination,
  RecordContextProvider,
  TextField,
  usePermissions,
  useTheme,
} from "react-admin";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import incomeStatementFilterList from "../../components/incomeStatements/filter/incomeStatementFilterList";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import IncomeStatementAsideList from "../../components/incomeStatements/aside/IncomeStatementAsideList";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import { toCurrencyAccounting } from "../../utils/formatter";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const MyDatagridRow = ({
  record,
  id,
  onToggleItem,
  children,
  hasBulkActions,
  rowClick,
  selected,
  selectable,
  ...props
}) => {
  return id ? (
    <RecordContextProvider value={record}>
      <TableRow {...props}>
        {/* data columns based on children */}
        {React.Children.map(children, (child) => {
          return React.isValidElement(child) && child.props.source ? (
            <TableCell
              key={`${id}-${child.props.source}`}
              align={child.type.textAlign || child.props.textAlign}
            >
              {child}
            </TableCell>
          ) : null;
        })}
      </TableRow>
    </RecordContextProvider>
  ) : null;
};

const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);
const MyDatagridConfigurable = (props) => (
  <DatagridConfigurable {...props} hover body={<MyDatagridBody />} />
);

export default function IncomeStatementList() {
  const [theme] = useTheme("light");
  const { permissions } = usePermissions();
  const list = React.useMemo(
    () => (
      <List
        perPage={100}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        aside={
          <IncomeStatementAsideList
            defaultFilter={{
              start_date: firstDate,
              end_date: lastDate,
            }}
          />
        }
        title="mine.label.income_statement"
        empty={false}
        actions={<DownloadAction />}
        filters={incomeStatementFilterList(permissions)}
        filterDefaultValues={{
          start_date: firstDate,
          end_date: lastDate,
        }}
        sort={{ field: "chart_of_accounts.code", order: "ASC" }}
        // queryOptions={{ meta: { sort: ["transactions.code ASC"] } }} // overide default sort
      >
        <MyDatagridConfigurable
          rowClick={false}
          bulkActionButtons={false}
          // expand={<AllTransactionJournalExpandList />}
          rowSx={(record, index) => {
            if (
              !record.transactions.length ||
              record.transactions[0].amount == 0
            ) {
              return {
                color: "#000",
                "& .RaDatagrid-rowCell": {
                  color: theme !== "dark" ? "grey.500" : "rgba(255,255,255,.4)",
                },
                "& .MuiTableCell-root": {
                  color: theme !== "dark" ? "grey.500" : "rgba(255,255,255,.4)",
                },
              };
            }
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField source="company.name" label="mine.tbl_header.company" />
          ) : null}
          {/* <TextField source="code" label="mine.tbl_header.code" /> */}
          <FunctionField
            source="name"
            label="mine.tbl_header.name"
            render={(record) => {
              let pad = { pl: 0 };
              switch (record.level) {
                case 2:
                  pad.pl = 4;
                  break;
                case 3:
                  pad.pl = 8;
                  break;
                case 4:
                  pad.pl = 12;
                  break;
                case 5:
                  pad.pl = 16;
                  break;
                default:
                  pad.pl = 0;
                  break;
              }
              return (
                <RecordContextProvider value={record}>
                  <TextField
                    source="name"
                    label="mine.tbl_header.name"
                    sx={pad}
                  />
                </RecordContextProvider>
              );
            }}
          />

          <FunctionField
            source="transactions[0].amount"
            label="mine.label.amount"
            textAlign="right"
            render={(record) => {
              let pad = { pr: 0 };
              switch (record.level) {
                case 2:
                  pad.pr = 4;
                  break;
                case 3:
                  pad.pr = 8;
                  break;
                case 4:
                  pad.pr = 12;
                  break;
                case 5:
                  pad.pr = 16;
                  break;
                default:
                  pad.pr = 0;
                  break;
              }
              let amountRecord = { transactions: [{ amount: 0 }] };
              if (record.transactions.length) {
                if (
                  !record.transactions[0].amount ||
                  typeof record.transactions[0].amount === "number"
                ) {
                  amountRecord.transactions[0].amount =
                    record.transactions[0].amount;
                } else if (
                  typeof record.transactions[0].amount === "string" &&
                  !isNaN(record.transactions[0].amount)
                ) {
                  amountRecord.transactions[0].amount =
                    +record.transactions[0].amount;
                } else {
                  amountRecord.transactions[0].amount =
                    record.transactions[0].amount;
                }
                amountRecord.transactions[0].amount = toCurrencyAccounting(
                  amountRecord.transactions[0].amount
                );
              }
              return (
                <RecordContextProvider value={amountRecord}>
                  <TextField
                    source="transactions[0].amount"
                    label="mine.label.amount"
                    sx={pad}
                    // textAlign="right"
                    emptyText="0"
                  />
                </RecordContextProvider>
              );
            }}
          />

          <FunctionField
            source="percentage"
            label="mine.label.percentage"
            textAlign="right"
            render={(record) => {
              let pad = { pr: 0 };
              switch (record.level) {
                case 2:
                  pad.pr = 4;
                  break;
                case 3:
                  pad.pr = 8;
                  break;
                case 4:
                  pad.pr = 12;
                  break;
                case 5:
                  pad.pr = 16;
                  break;
                default:
                  pad.pr = 0;
                  break;
              }
              return (
                <RecordContextProvider value={record}>
                  <NumberField
                    source="percentage"
                    label="mine.label.percentage"
                    sx={pad}
                    emptyText="0"
                  />
                </RecordContextProvider>
              );
            }}
          />
        </MyDatagridConfigurable>
      </List>
    ),
    [theme]
  );

  return list;
}
