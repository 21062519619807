import { useEffect, useMemo } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  NumberField,
  useTheme,
  usePermissions,
  ShowButton,
  useRecordContext,
  useListContext,
  useGetRecordId,
  WrapperField,
} from "react-admin";
import generalLedgerFilterList from "../../components/generalLedgers/filter/generalLedgerFilterList";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import GeneralLedgerExpandList from "../../components/generalLedgers/expand/GeneralLedgerExpandList";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const ExpandAll = () => {
  useEffect(() => {
    const expandAllBtn = document.querySelector(
      "table.RaDatagrid-table .RaDatagrid-thead .RaDatagrid-headerRow .RaDatagrid-expandHeader .RaDatagrid-expandIcon:not(.RaDatagrid-expanded)"
    );
    if (expandAllBtn) {
      var clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      expandAllBtn.dispatchEvent(clickEvent);
    }
  }, []);
  return null;
};

const ShowBtn = (props) => {
  const list = useListContext();
  const record = useRecordContext();
  const recordId = useGetRecordId();

  const newRecord = {
    id:
      record.id +
      "/show?filter=" +
      encodeURIComponent(
        JSON.stringify({
          chart_of_account_id: recordId,
          ...list.filterValues,
        })
      ),
  };

  return (
    <ShowButton
      // resource={resource}
      record={newRecord}
      // Lihat: https://marmelab.com/react-admin/ListTutorial.html#linking-to-a-pre-sorted-list
      // component={Link}
      // to={{
      //   pathname: "/posts",
      //   search: stringify({
      //     page: 1,
      //     perPage: 25,
      //     sort: "nb_views",
      //     order: "DESC",
      //     filter: {},
      //   }),
      // }}
      to={
        record.id + // id ini klitannya tdk penting tp penting
        "/show?filter=" +
        encodeURIComponent(
          JSON.stringify({
            chart_of_account_id: recordId,
            ...list.filterValues,
          })
        )
      }
      {...props}
    />
  );
};

const GeneralLedgerList = () => {
  // const t = useTranslate();

  const [theme] = useTheme("light");
  const { permissions } = usePermissions();

  const list = useMemo(
    () => (
      <List
        title="mine.list_title.general_ledger"
        empty={false}
        actions={<DownloadAction />}
        filters={generalLedgerFilterList(permissions)}
        filterDefaultValues={{
          start_date: firstDate,
          end_date: lastDate,
        }}
        sort={{ field: "chart_of_accounts.code", order: "ASC" }}
        // queryOptions={{ meta: { sort: ["transactions.code ASC"] } }} // overide default sort
      >
        <DatagridConfigurable
          rowClick={false}
          bulkActionButtons={false}
          expand={<GeneralLedgerExpandList />}
          isRowExpandable={(row) => !!row.transactions.length}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField source="company_name" label="mine.tbl_header.company" />
          ) : null}
          <TextField source="code" label="mine.tbl_header.code" />
          <TextField source="name" label="mine.tbl_header.name" />
          <ExpandAll />
          <NumberField source="previous" label="mine.label.previous" />
          <NumberField source="debit" />
          <NumberField source="credit" />
          <NumberField source="current" label="Akhir" />
          {/* <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <ShowBtn label="mine.label.show_detail" />
          </WrapperField> */}
        </DatagridConfigurable>
      </List>
    ),
    [theme]
  );

  return list;
};

export default GeneralLedgerList;
