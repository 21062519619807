import { useMemo } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  useTheme,
  NumberField,
  usePermissions,
} from "react-admin";
import InventoryHistoryExpandList from "../../components/inventoryHistories/expand/InventoryHistoryExpandList";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import MyDatagridBody from "../../components/common/MyDatagridBody";
import InventoryHistoryFooterList from "../../components/inventoryHistories/footer/InventoryHistoryFooterList";
import inventoryHistoryFilterList from "../../components/inventoryHistories/filter/inventoryHistoryFilterList";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const InventoryHistoryList = () => {
  const { permissions } = usePermissions();
  const [theme] = useTheme("light");

  const list = useMemo(
    () => (
      <List
        storeKey={false} // Disabling Parameters Persistence
        title="mine.list_title.inventory_history"
        empty={false}
        actions={<DownloadAction field="products.code" order="ASC" />}
        filters={inventoryHistoryFilterList(permissions)}
        filterDefaultValues={{
          start_date: firstDate,
          end_date: lastDate,
        }}
        queryOptions={{ meta: { sort: ["products.code ASC"] } }} // overide default sort
        sx={{
          flexDirection: "column",
        }}
      >
        <DatagridConfigurable
          body={<MyDatagridBody footer={<InventoryHistoryFooterList />} />}
          bulkActionButtons={false}
          expand={<InventoryHistoryExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          <TextField source="code" label="mine.label.product_code" />
          <NumberField source="initial" label="mine.label.initial" />
          <NumberField
            source="initial_value"
            label="mine.label.initial_value"
          />
          <NumberField source="total_in" label="mine.tbl_header.total_in" />
          <NumberField source="total_in_value" label="mine.label.value_in" />
          <NumberField source="total_out" label="mine.tbl_header.total_out" />
          <NumberField source="total_out_value" label="mine.label.value_out" />
          <NumberField source="final" label="mine.label.final" />
          <NumberField source="final_value" label="mine.label.final_value" />
        </DatagridConfigurable>
      </List>
    ),
    [theme, permissions]
  );

  return list;
};

export default InventoryHistoryList;
