import { useEffect, useState } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  useTheme,
  NumberField,
} from "react-admin";
import accountPayableCardFilterList from "../../components/accountPayableCards/filter/accountPayableCardFilterList";
import AccountPayableCardExpandList from "../../components/accountPayableCards/expand/AccountPayableCardExpandList";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import MyDatagridBody from "../../components/common/MyDatagridBody";
import AccountPayableCardFooterList from "../../components/accountPayableCards/footer/AccountPayableCardFooterList";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const ExpandAll = () => {
  useEffect(() => {
    const expandAllBtn = document.querySelector(
      "table.RaDatagrid-table .RaDatagrid-thead .RaDatagrid-headerRow .RaDatagrid-expandHeader .RaDatagrid-expandIcon:not(.RaDatagrid-expanded)"
    );
    if (expandAllBtn) {
      var clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      expandAllBtn.dispatchEvent(clickEvent);
    }
  }, []);
  return null;
};

const AccountPayableCardList = () => {
  const [theme] = useTheme("light");

  return (
    <List
      title="mine.list_title.account_payable_card"
      empty={false}
      actions={<DownloadAction />}
      filters={accountPayableCardFilterList}
      sort={{ field: "suppliers.id", order: "ASC" }}
      filterDefaultValues={{
        start_date: firstDate,
        end_date: lastDate,
      }}
      // queryOptions={{ meta: { sort: ["suppliers.created_at ASC"] } }} // overide default sort
    >
      <DatagridConfigurable
        body={<MyDatagridBody footer={<AccountPayableCardFooterList />} />}
        bulkActionButtons={false}
        expand={<AccountPayableCardExpandList />}
        isRowExpandable={(row) =>
          row.company_debt_items && !!row.company_debt_items.length
        }
        sx={{
          "& .RaDatagrid-expandedPanel > td": {
            p: 0,
            bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
          },
        }}
      >
        <TextField source="code" label="mine.label.supplier_code" />
        <ExpandAll />
        <TextField source="name" label="mine.label.supplier_name" />
        <NumberField
          source="total_initial"
          label="mine.label.initial_balance"
        />
        <NumberField source="total_debit" label="mine.label.debit" />
        <NumberField source="total_credit" label="mine.label.credit" />
        <NumberField source="total_final" label="mine.label.final_balance" />
      </DatagridConfigurable>
    </List>
  );
};

export default AccountPayableCardList;
