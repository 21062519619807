import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import {
  useUpdate,
  useNotify,
  SimpleForm,
  Edit,
  useResourceContext,
  required,
  number,
  maxLength,
  useRefresh,
  minLength,
  AutocompleteInput,
  TextInput,
  DateInput,
  useTranslate,
  ReferenceInput,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import { toCurrency } from "../../../utils/formatter";
import RadioGroupX from "../common/RadioGroupX";
import Box from "@mui/material/Box";
import TabForm from "../common/TabForm";
import currentDate from "../../../utils/currentDate";
import {
  customerFilterToQuery,
  customerInputText,
  customerMatchSuggestion,
  CustomerOptionRenderer,
} from "../common/CustomerSelectOption";
import GiroInputs from "../common/GiroInputs";
import parserFormatNumInput from "../../../utils/parserFormatNumInput";
import CodeNumberInput from "../../common/CodeNumberInput";

const equalOrGreaterThanPaymentItem = (value, allValues, elm) => {
  let count = 0;
  for (let i = 0; i < allValues.bad_debt_installment_items.length; i++) {
    const elm = allValues.bad_debt_installment_items[i];
    count += elm.amount;
  }
  if (count > value) {
    return (
      "Not enough amount to accommodate all item's payment (" +
      toCurrency(true).format(count) +
      ")"
    );
  }
  return undefined;
};

const validateNumber = [required(), minLength(14), maxLength(14)];
const validateDate = [required(), minLength(10), maxLength(10)];
const validateCustomer = [required(), minLength(36), maxLength(36)];
const validatePrice = [required(), number(), equalOrGreaterThanPaymentItem];
const validateNote = [required()];

export default function BadDebtInstallmentEditDialog({
  open,
  setOpen,
  recordId,
}) {
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource
  const t = useTranslate();

  const save = useCallback(
    async (values) => {
      values.bad_debt_id =
        values.bad_debt_installment_items[0].bad_debt_item.bad_debt_id;
      try {
        await update(
          resource,
          { id: values.id, data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.updated", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          if (error.body.errors === "403") {
            // munculkan notifikasi erro ketika sudah approved
            notify("mine.notification.403_error", { type: "error" });
          } else {
            // The shape of the returned validation errors must match the shape of the form
            return error.body.errors;
          }
        }
      }
    },
    [update]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted={false}
      fullWidth={true}
      maxWidth="md"
    >
      <Edit
        title={" "}
        id={recordId}
        actions={false}
        sx={{ "& .RaEdit-main": { mt: 0 } }}
      >
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns callback={handleClose} />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>
              {t("mine.dialog.edit_bad_debt_installment")}
            </DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent sx={{ alignSelf: "normal" }}>
            <TextInput disabled source="id" sx={{ display: "none" }} />

            <CodeNumberInput
              open={open}
              dependOn="date"
              variant="outlined"
              source="code"
              label="mine.label.code"
              fullWidth
              validate={validateNumber}
              disabled
            />
            <DateInput
              variant="outlined"
              source="date"
              label="mine.label.date"
              fullWidth
              isRequired
              validate={validateDate}
              defaultValue={currentDate()}
            />
            <ReferenceInput
              source="bad_debt_installment_items[0].bad_debt_item.bad_debt_id"
              reference="bad-debts"
              perPage={10}
              sort={{ field: "bad_debts.created_at", order: "ASC" }}
              filter={{
                hasInstallments: true,
                "bad_debts.type": "2",
                "bad_debts.approved": true,
              }}
            >
              <AutocompleteInput
                label="mine.label.customer"
                variant="outlined"
                isRequired
                fullWidth
                validate={validateCustomer}
                optionValue="id"
                optionText={<CustomerOptionRenderer />}
                inputText={customerInputText}
                matchSuggestion={customerMatchSuggestion}
                filterToQuery={customerFilterToQuery}
              />
            </ReferenceInput>
            <RadioGroupX
              sx={{
                "& .MuiFormGroup-root": { justifyContent: "space-around" },
              }}
              choices={[
                { id: 1, name: "Transfer" },
                { id: 2, name: "Cash" },
                { id: 3, name: "Giro" },
              ]}
              label="mine.label.type"
            />
            <GiroInputs />
            <TextInput
              variant="outlined"
              source="total"
              label="mine.label.total_payment"
              validate={validatePrice}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Rp</InputAdornment>
                ),
              }}
              {...parserFormatNumInput}
            />
            <TextInput
              variant="outlined"
              source="note"
              label="mine.label.note"
              fullWidth
              multiline
              rows={3}
              validate={validateNote}
            />
            <TabForm schema="edit" />
          </DialogContent>
        </SimpleForm>
      </Edit>
    </Dialog>
  );
}
