import { DateInput, TextInput } from "react-admin";

const accountPayableCardFilterList = [
  <DateInput
    alwaysOn
    source="start_date"
    variant="outlined"
    label="mine.label.start_date"
  />,
  <DateInput
    alwaysOn
    source="end_date"
    //FIXME: semua DateInput tidak bisa di tambah resettable
    // resettable
    variant="outlined"
    label="mine.label.end_date"
  />,
  <TextInput
    alwaysOn
    resettable
    source="code"
    variant="outlined"
    label="mine.label.code"
  />,
  <TextInput
    alwaysOn
    resettable
    source="name"
    variant="outlined"
    label="mine.label.name"
  />,
];

export default accountPayableCardFilterList;
