import {
  DateField,
  useRecordContext,
  RecordContextProvider,
  NumberField,
  TabbedShowLayout,
  useTranslate,
} from "react-admin";
import Box from "@mui/material/Box";

const FactoryDebtCorrectionExpandList = () => {
  const record = useRecordContext();
  const t = useTranslate();
  return (
    <TabbedShowLayout
      syncWithLocation={false}
      sx={{
        m: 4,
        backgroundClip: "padding-box",
        boxShadow: (theme) => {
          return theme.components.MuiPaper.styleOverrides.elevation1.boxShadow;
        },
        borderRadius: "4px",
        bgcolor: "background.paper",
        "& .RaTabbedShowLayout-content": {
          p: 0,
        },
        "& .RaLabeled-label": {
          display: "none",
        },
      }}
    >
      <TabbedShowLayout.Tab label={t("mine.label.correction")}>
        <Box
          component="table"
          sx={{
            width: "100%",
            // bgcolor: "rgba(0,0,0,.04)",
            fontSize: "smaller",
            borderCollapse: "collapse",
            "& th, td": {
              padding: "6px 8px",
              border: "1px solid #dddddd",
            },
          }}
        >
          <thead>
            <tr>
              <th>Purchase order code</th>
              <th>{t("mine.label.transaction_date")}</th>
              <th>{t("mine.label.due_date")}</th>
              <th style={{ textAlign: "right" }}>{t("mine.label.debt")}</th>
              <th style={{ textAlign: "right" }}>{t("mine.label.remain")}</th>
              <th style={{ textAlign: "right" }}>
                {t("mine.label.correction")}
              </th>
            </tr>
          </thead>
          <tbody>
            {record.factory_debt_correction_items.map((item, idx) => {
              return (
                <tr key={item.id + idx}>
                  <td>{item.purchase_order.code}</td>
                  <td>
                    <RecordContextProvider value={item}>
                      <DateField
                        source="purchase_order.date" // FIXME: Jangan pakai created at
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td>
                    <RecordContextProvider value={item}>
                      <DateField
                        source="purchase_order.company_debt.due_date"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="debt"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider
                      value={{ remain: item.debt - item.amount }}
                    >
                      <NumberField
                        source="remain"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="amount"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Box>
      </TabbedShowLayout.Tab>
      {record.transactions.length && (
        <TabbedShowLayout.Tab label={t("mine.label.journal")}>
          <Box
            component="table"
            sx={{
              width: "100%",
              // bgcolor: "rgba(0,0,0,.04)",
              fontSize: "smaller",
              borderCollapse: "collapse",
              "& th, td": {
                padding: "6px 8px",
                border: "1px solid #dddddd",
              },
            }}
          >
            <thead>
              <tr>
                <th>COA</th>
                <th>{t("mine.label.name")}</th>
                <th>{t("mine.label.note")}</th>
                <th style={{ textAlign: "right" }}>Debit</th>
                <th style={{ textAlign: "right" }}>{t("mine.label.credit")}</th>
              </tr>
            </thead>
            <tbody>
              {record.transactions.map((item, idx) => {
                return (
                  <tr key={item.id + idx}>
                    <td>{item.coa.code}</td>
                    <td>{item.coa.name}</td>
                    <td>{item.description}</td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="debit"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="credit"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Box>
        </TabbedShowLayout.Tab>
      )}
      {!record.transactions.length && (
        <TabbedShowLayout.Tab label={t("mine.label.journal") + "*"}>
          <Box
            component="table"
            sx={{
              width: "100%",
              // bgcolor: "rgba(0,0,0,.04)",
              fontSize: "smaller",
              borderCollapse: "collapse",
              "& th, td": {
                padding: "6px 8px",
                border: "1px solid #dddddd",
              },
            }}
          >
            <thead>
              <tr>
                <th>COA</th>
                <th>{t("mine.label.name")}</th>
                <th>{t("mine.label.note")}</th>
                <th style={{ textAlign: "right" }}>Debit</th>
                <th style={{ textAlign: "right" }}>{t("mine.label.credit")}</th>
              </tr>
            </thead>
            <tbody>
              {record.factory_debt_correction_journals.map((item, idx) => {
                return (
                  <tr key={item.id + idx}>
                    <td>{item.chart_of_account.code}</td>
                    <td>{item.chart_of_account.name}</td>
                    <td>{item.description}</td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="debit"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="credit"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Box>
        </TabbedShowLayout.Tab>
      )}
    </TabbedShowLayout>
  );
};

export default FactoryDebtCorrectionExpandList;
