import { forwardRef, Fragment } from "react";
import Box from "@mui/material/Box";
import classes from "./PrintContent.module.css";
import Typography from "@mui/material/Typography";
import {
  DateField,
  NumberField,
  RecordContextProvider,
  // usePermissions,
} from "react-admin";
import splitArrayToChunks from "../../../utils/splitArrayToChunks";

const PrintContent = forwardRef((props, ref) => {
  // eslint-disable-line max-len
  // const { permissions, isLoading } = usePermissions();

  return (
    <Box
      component="div"
      sx={{
        display: "none",
      }}
    >
      <Box
        ref={ref}
        sx={{
          fontSize: "12px",
          py: 2,
          px: 2,
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 40px)",
          fontFamily: "'Source Code Pro', monospace",
        }}
      >
        <style type="text/css" media="print">
          {`@page {
              width: 215.9mm;
              height: 139.7mm;
              size: 8.5in 5.5in portrait;
              margin: 0;
            }
            html, body {
              width: 215.9mm;
              height: 100%;
            }
            .page-break {
              display: block;
              page-break-after: always;
            }
          `}
        </style>
        {splitArrayToChunks(props.record.factory_debt_payment_items, 2).map(
          (arr, i, ori) => {
            return (
              <Fragment key={i}>
                <Box sx={i > 0 ? { mt: 2 } : {}}></Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    mb: 2,
                    textTransform: "uppercase",
                    borderBottom: "1px solid #000",
                    textAlign: "center",
                    fontFamily: "inherit",
                  }}
                >
                  bukti pembayaran hutang
                </Typography>
                <table className={classes.headerSj}>
                  <thead>
                    <tr>
                      <th style={{ width: "17%" }}>Dibayarkan Kepada</th>
                      <th>:</th>
                      <th style={{ width: "53%" }}>
                        {props.record.supplier.name}
                      </th>
                      <th style={{ width: "12%" }}>Tanggal</th>
                      <th>:</th>
                      <th style={{ width: "18%" }}>
                        <RecordContextProvider value={props.record}>
                          <DateField
                            source="date"
                            sx={{
                              fontWeight: "inherit",
                              fontSize: "12px",
                              fontFamily: "inherit",
                            }}
                          ></DateField>
                        </RecordContextProvider>
                      </th>
                    </tr>
                    <tr>
                      <th>Tipe Bayar</th>
                      <th>:</th>
                      <th>{props.record.type === 1 ? "Transfer" : "Cash"}</th>
                      <th>No. Bukti</th>
                      <th>:</th>
                      <th>{props.record.code}</th>
                    </tr>
                  </thead>
                </table>
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mb: 1,
                    fontFamily: "inherit",
                  }}
                >
                  Keterangan: {props.record.note}
                </Typography>
                <table className={classes.tableSj}>
                  <thead>
                    <tr>
                      <th>No. Transaksi</th>
                      <th>Tgl. Transaksi</th>
                      <th>Tgl. Jatuh Tempo</th>
                      <th style={{ textAlign: "right" }}>Jumlah Tertagih</th>
                      <th style={{ textAlign: "right" }}>Jumlah Bayar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arr.map((obj, idx) => {
                      return (
                        <tr key={obj.id}>
                          <td>{obj.purchase_order.code}</td>
                          <td>
                            <RecordContextProvider value={obj.purchase_order}>
                              <DateField
                                source="date"
                                sx={{
                                  fontWeight: "inherit",
                                  fontSize: "12px",
                                  fontFamily: "inherit",
                                }}
                              ></DateField>
                            </RecordContextProvider>
                          </td>
                          <td>
                            <RecordContextProvider
                              value={obj.purchase_order.company_debt}
                            >
                              <DateField
                                source="due_date"
                                sx={{
                                  fontWeight: "inherit",
                                  fontSize: "12px",
                                  fontFamily: "inherit",
                                }}
                              ></DateField>
                            </RecordContextProvider>
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <RecordContextProvider value={obj}>
                              <NumberField
                                source="debt"
                                sx={{
                                  fontWeight: "inherit",
                                  fontSize: "12px",
                                  fontFamily: "inherit",
                                }}
                              ></NumberField>
                            </RecordContextProvider>
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <RecordContextProvider value={obj}>
                              <NumberField
                                source="amount"
                                sx={{
                                  fontWeight: "inherit",
                                  fontSize: "12px",
                                  fontFamily: "inherit",
                                }}
                              ></NumberField>
                            </RecordContextProvider>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        colSpan={2}
                        style={{
                          textAlign: "right",
                          verticalAlign: "baseline",
                          fontWeight: "bold",
                          borderRight: "none",
                        }}
                      >
                        Total:
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          verticalAlign: "baseline",
                          fontWeight: "bold",
                          borderLeft: "none",
                        }}
                      ></td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={props.record}>
                          <NumberField
                            source="total_debt"
                            sx={{
                              fontWeight: "inherit",
                              fontSize: "12px",
                              fontFamily: "inherit",
                            }}
                          ></NumberField>
                        </RecordContextProvider>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={props.record}>
                          <NumberField
                            source="total"
                            sx={{
                              fontWeight: "inherit",
                              fontSize: "12px",
                              fontFamily: "inherit",
                            }}
                          ></NumberField>
                        </RecordContextProvider>
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <Typography
                  variant="caption"
                  sx={{
                    mt: "auto",
                    textTransform: "capitalize",
                    fontFamily: "inherit",
                  }}
                >
                  {"Terbilang: # " + props.record.total_in_words + " Rupiah #"}
                </Typography>
                <table
                  style={{ marginTop: "8px" }}
                  className={classes.tableACC}
                >
                  <thead>
                    <tr>
                      <th>Mengetahui</th>
                      <th colSpan={2}>Pelaksana</th>
                    </tr>
                    <tr>
                      <th style={{ textTransform: "uppercase", width: "33.33%" }}>
                        Kepala Depo
                      </th>
                      <th style={{ textTransform: "uppercase", width: "33.33%" }}>Akunting</th>
                      <th style={{ textTransform: "uppercase", width: "33.33%" }}>Finance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td style={{ paddingTop: "80px" }}></td>
                    </tr>
                  </tbody>
                </table>
                <div className="page-break" />
              </Fragment>
            );
          }
        )}
      </Box>
    </Box>
  );
});
export default PrintContent;
