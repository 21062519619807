import {
  useRecordContext,
  RecordContextProvider,
  NumberField,
  useTranslate,
  DateField,
  TextField,
} from "react-admin";
import Box from "@mui/material/Box";

const ProductRankingExpandList = () => {
  const record = useRecordContext();
  const t = useTranslate();

  return (
    <Box
      sx={{
        m: 5,
        backgroundClip: "padding-box",
        boxShadow: (theme) => {
          return theme.components.MuiPaper.styleOverrides.elevation1.boxShadow;
        },
        borderRadius: "4px",
        bgcolor: "background.paper",
      }}
    >
      <Box
        component="table"
        sx={{
          width: "100%",
          // bgcolor: "rgba(0,0,0,.04)",
          fontSize: "smaller",
          borderCollapse: "collapse",
          "& th, td": {
            padding: "6px 8px",
            border: "1px solid #dddddd",
          },
        }}
      >
        <thead>
          <tr>
            <th style={{ width: "5.6%" }}>{t("mine.label.sale_code")}</th>
            <th style={{ width: "5.6%" }}>{t("mine.label.product_code")}</th>
            <th style={{ textAlign: "right", width: "6.6%" }}>Quantity</th>
            <th style={{ textAlign: "right", width: "7.3%" }}>
              {t("mine.label.sale_price")}
            </th>
            <th style={{ textAlign: "right", width: "3.9%" }}>
              {t("mine.label.cogs")}
            </th>
            <th style={{ textAlign: "right", width: "7.3%" }}>
              Subtotal {t("mine.expand_menu.sale")}
            </th>
            <th style={{ textAlign: "right", width: "7.3%" }}>
              Subtotal {t("mine.label.cogs")}
            </th>
            <th style={{ textAlign: "right", width: "7.3%" }}>
              Subtotal Profit
            </th>
            <th style={{ textAlign: "right", width: "7.3%" }}>
              Subtotal {t("mine.label.profit_average")}
            </th>
          </tr>
        </thead>
        <tbody>
          {record.sales.map((item, idx) => {
            return (
              <tr key={item.id + idx}>
                <td>
                  <RecordContextProvider value={item}>
                    <TextField
                      source="sale_number"
                      sx={{
                        fontSize: "inherit",
                      }}
                    />
                  </RecordContextProvider>
                </td>
                <td>
                  <RecordContextProvider value={item}>
                    <TextField
                      source="product_code"
                      sx={{
                        fontSize: "inherit",
                      }}
                    />
                  </RecordContextProvider>
                </td>
                <td style={{ textAlign: "right" }}>
                  <RecordContextProvider value={item}>
                    <NumberField
                      source="sub_total_quantity_sold"
                      sx={{
                        fontSize: "inherit",
                      }}
                    />
                  </RecordContextProvider>
                </td>
                <td style={{ textAlign: "right" }}>
                  <RecordContextProvider value={item}>
                    <NumberField
                      source="cos"
                      sx={{
                        fontSize: "inherit",
                      }}
                    />
                  </RecordContextProvider>
                </td>
                <td style={{ textAlign: "right" }}>
                  <RecordContextProvider value={item}>
                    <NumberField
                      source="cogs"
                      sx={{
                        fontSize: "inherit",
                      }}
                    />
                  </RecordContextProvider>
                </td>
                <td style={{ textAlign: "right" }}>
                  <RecordContextProvider value={item}>
                    <NumberField
                      source="sub_total_sale"
                      sx={{
                        fontSize: "inherit",
                      }}
                    />
                  </RecordContextProvider>
                </td>
                <td style={{ textAlign: "right" }}>
                  <RecordContextProvider value={item}>
                    <NumberField
                      source="sub_total_cogs"
                      sx={{
                        fontSize: "inherit",
                      }}
                    />
                  </RecordContextProvider>
                </td>
                <td style={{ textAlign: "right" }}>
                  <RecordContextProvider value={item}>
                    <NumberField
                      source="sub_total_profit"
                      sx={{
                        fontSize: "inherit",
                      }}
                    />
                  </RecordContextProvider>
                </td>
                <td style={{ textAlign: "right" }}>
                  <RecordContextProvider value={item}>
                    <NumberField
                      source="sub_total_average_profit"
                      sx={{
                        fontSize: "inherit",
                      }}
                    />
                  </RecordContextProvider>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Box>
    </Box>
  );
};

export default ProductRankingExpandList;
