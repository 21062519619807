import { AutocompleteInput, DateInput, ReferenceInput } from "react-admin";

const customerRankingFilterList = (permissions) => {
  const defaultFilter = [
    <DateInput
      alwaysOn
      source="start_date"
      label="mine.label.start_date"
      variant="outlined"
    />,
    <DateInput
      alwaysOn
      source="end_date"
      label="mine.label.end_date"
      variant="outlined"
    />,
  ];
  if (permissions && permissions.update_company) {
    defaultFilter.splice(
      1,
      0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        sort={{ field: "companies.alias", order: "ASC" }}
      >
        <AutocompleteInput
          resettable
          optionText="alias"
          variant="outlined"
          label="mine.label.company"
        />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};
export default customerRankingFilterList;
