import { useMemo } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  useTheme,
  NumberField,
  usePermissions,
} from "react-admin";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import accountPayableHistoryFilterList from "../../components/accountPayableHistories/filter/accountPayableHistoryFilterList";
import AccountPayableHistoryExpandList from "../../components/accountPayableHistories/expand/AccountPayableHistoryExpandList";
import MyDatagridBody from "../../components/common/MyDatagridBody";
import AccountPayableHistoryFooterList from "../../components/accountPayableHistories/footer/AccountPayableHistoryFooterList";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const AccountPayableHistoryList = () => {
  const { permissions } = usePermissions();
  const [theme] = useTheme("light");

  const list = useMemo(
    () => (
      <List
        storeKey={false} // Disabling Parameters Persistence
        title="mine.list_title.account_payable_history"
        empty={false}
        // aside={<AccountPayableHistoryAsideList />}
        actions={<DownloadAction />}
        filters={accountPayableHistoryFilterList(permissions)}
        filterDefaultValues={{
          start_date: firstDate,
          end_date: lastDate,
        }}
        queryOptions={{ meta: { sort: ["suppliers.code ASC"] } }} // overide default sort
        sx={{
          flexDirection: "column",
        }}
      >
        <DatagridConfigurable
          // TODO semua data grid yg ada Grand Total harus diganti dengan cara ini
          body={<MyDatagridBody footer={<AccountPayableHistoryFooterList />} />}
          bulkActionButtons={false}
          // rowClick={rowClickHandler}
          expandSingle={true}
          isRowExpandable={(row) => !!row.company_debts} // sale_orders jika jenisnya per salesman, sale_items kalau per jenis
          expand={<AccountPayableHistoryExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          <TextField
            source="code"
            label="mine.label.code"
            emptyText="-"
            sx={{ width: "5%" }}
          />
          <TextField source="name" label="mine.label.name" />
          <NumberField
            source="total_payment"
            label="mine.label.payment"
            emptyText="0"
          />
          <NumberField
            source="total_correction"
            label="mine.label.correction"
            emptyText="0"
          />
          <NumberField
            source="total_remaining_debt"
            label="mine.label.remain"
            emptyText="0"
          />
        </DatagridConfigurable>
      </List>
    ),
    [theme, permissions]
  );

  return list;
};

export default AccountPayableHistoryList;
