import {
  useRecordContext,
  RecordContextProvider,
  NumberField,
  TabbedShowLayout,
  useTranslate,
  DateField,
} from "react-admin";
import Box from "@mui/material/Box";

const AccountReceivablePcmAgeExpandList = () => {
  const record = useRecordContext();
  const t = useTranslate();
  return (
    <Box
      sx={{
        m: 5,
        backgroundClip: "padding-box",
        boxShadow: (theme) => {
          return theme.components.MuiPaper.styleOverrides.elevation1.boxShadow;
        },
        borderRadius: "4px",
        bgcolor: "background.paper", // "#fff",
      }}
    >
      {record.checks ? (
        <Box
          component="table"
          sx={{
            width: "100%",
            // bgcolor: "rgba(0,0,0,.04)",
            fontSize: "smaller",
            borderCollapse: "collapse",
            "& th, td": {
              padding: "6px 8px",
              border: "1px solid #dddddd",
            },
          }}
        >
          <thead>
            <tr>
              <th>{t("mine.label.sale_code")}</th>
              <th>{t("mine.label.sale_date")}</th>
              <th>{t("mine.label.check_number")}</th>
              <th>{t("mine.tbl_header.bank_name")}</th>
              <th style={{ textAlign: "right" }}>Total</th>
              <th>{t("mine.label.effective_date")}</th>
              <th style={{ textAlign: "right" }}>Settlement Days</th>
              <th style={{ textAlign: "right" }}>Over Due Days</th>
            </tr>
          </thead>
          <tbody>
            {record.checks.map((item, idx) => {
              return (
                <tr key={item.sale_code + idx}>
                  <td>{item.sale_code}</td>
                  <td>
                    <RecordContextProvider value={item}>
                      <DateField
                        source="sale_date"
                        options={{
                          year: "numeric",
                          month: "short",
                          day: "2-digit",
                        }}
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td>{item.check_number}</td>
                  <td>{item.bank_name}</td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="balance"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td>
                    <RecordContextProvider value={item}>
                      <DateField
                        source="effective_date"
                        options={{
                          year: "numeric",
                          month: "short",
                          day: "2-digit",
                        }}
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="settlement_days"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="over_due_days"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Box>
      ) : null}
    </Box>
  );
};

export default AccountReceivablePcmAgeExpandList;
