import { AutocompleteInput, DateInput, ReferenceInput } from "react-admin";

const accountPayableHistoryFilterList = (permissions) => {
  const defaultFilter = [
    <DateInput
      alwaysOn
      source="start_date"
      variant="outlined"
      label="mine.label.start_date"
    />,
    <DateInput
      alwaysOn
      source="end_date"
      //FIXME: semua DateInput tidak bisa di tambah resettable
      // resettable
      variant="outlined"
      label="mine.label.end_date"
    />,
    <ReferenceInput
      alwaysOn
      source="start_created_at"
      reference="suppliers"
      sort={{ field: "suppliers.created_at", order: "ASC" }}
      perPage={10}
    >
      <AutocompleteInput
        resettable
        optionValue="created_at"
        optionText="code"
        variant="outlined"
        label="mine.label.start_code"
      />
    </ReferenceInput>,
    <ReferenceInput
      alwaysOn
      source="end_created_at"
      reference="suppliers"
      sort={{ field: "suppliers.created_at", order: "ASC" }}
      perPage={10}
    >
      <AutocompleteInput
        resettable
        optionValue="created_at"
        optionText="code"
        variant="outlined"
        label="mine.label.end_code"
        // filterToQuery={(search) => ({ created_at: search })}
      />
    </ReferenceInput>,
  ];
  if (permissions && permissions.update_company) {
    defaultFilter.unshift(
      // defaultFilter.splice(
      // 1,
      // 0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        sort={{ field: "companies.alias", order: "ASC" }}
      >
        <AutocompleteInput
          resettable
          optionText="alias"
          variant="outlined"
          label="mine.label.company"
        />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};

export default accountPayableHistoryFilterList;
