import { AutocompleteInput, ReferenceInput } from "react-admin";
import {
  filterToQuery,
  inputText,
  matchSuggestion,
  OptionRenderer,
} from "../../customerDebtPayments/common/CustomSelectOption";

const accountReceivablePcmFilterList = (permissions) => {
  const defaultFilter = [
    <ReferenceInput
      alwaysOn
      source="customer_id"
      reference="customers"
      sort={{ field: "customers.code", order: "ASC" }}
      perPage={10}
    >
      <AutocompleteInput
        resettable
        variant="outlined"
        label="mine.label.customer"
        optionValue="id"
        optionText={<OptionRenderer />}
        inputText={inputText}
        matchSuggestion={matchSuggestion}
        filterToQuery={filterToQuery}
        sx={{ minWidth: "400px" }}
      />
    </ReferenceInput>,
  ];
  if (permissions && permissions.update_company) {
    defaultFilter.unshift(
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        sort={{ field: "companies.alias", order: "ASC" }}
      >
        <AutocompleteInput
          resettable
          optionText="alias"
          variant="outlined"
          label="mine.label.company"
        />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};

export default accountReceivablePcmFilterList;
