import { useMemo, useState } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  NumberField,
  useTheme,
  usePermissions,
  useTranslate,
} from "react-admin";
import customerRankingFilterList from "../../components/customerRankings/filter/customerRankingFilterList";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const Aside = () => {
  // const t = useTranslate();
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <h5>
        <h3>Grade Bulan</h3>
        <h5>A = 12</h5>
        <h5>B &gt; 9</h5>
        <h5>C &gt; 6</h5>
        <h5>D &gt; 3</h5>
        <h5>E &lt; 3</h5>
        <br />
        <h3>Grade Quantity</h3>
        <h5>A &gt; 3000</h5>
        <h5>B &gt; 2500</h5>
        <h5>C &gt; 2000</h5>
        <h5>D &gt; 1000</h5>
        <h5>E &lt; 1000</h5>
        <br />
        <h3>Grade Rata-Rata Profit</h3>
        <h5>A &gt; 8.000</h5>
        <h5>B &gt; 6.000</h5>
        <h5>C &gt; 4.000</h5>
        <h5>D &gt; 2.000</h5>
        <h5>E &lt; 2.000</h5>
        <br />
        <h3>Grade Cont. Profit</h3>
        <h5>A &gt; 25.000.000</h5>
        <h5>B &gt; 20.000.000</h5>
        <h5>C &gt; 15.000.000</h5>
        <h5>D &gt; 5.000.000</h5>
        <h5>E &lt; 5.000.000</h5>
        <br />
        <h3>Grade Loan Tenor</h3>
        <h5>A &lt;= 30</h5>
        <h5>B &lt;= 60</h5>
        <h5>C &lt;= 90</h5>
        <h5>D &lt;= 120</h5>
        <h5>E &gt; 120</h5>
      </h5>
    </div>
  );
};

const CustomerRankingList = (props) => {
  const { permissions } = usePermissions();
  const [theme] = useTheme("light");
  const t = useTranslate();
  const [showAside, setShowAside] = useState(false);

  const rowClickHandler = (id, basePath, record) => {
    // console.log("Lets", id, basePath, record);
    setShowAside((prev) => !prev);
  };

  const list = useMemo(() => {
    let filterDefaultValues = {
      start_date: firstDate,
      end_date: lastDate,
    };

    return (
      <List
        title="mine.list_title.customer_ranking"
        empty={false}
        aside={showAside ? <Aside /> : null}
        actions={<DownloadAction field="cont_prof" order="DESC" />}
        filters={customerRankingFilterList(permissions)}
        filterDefaultValues={filterDefaultValues}
        queryOptions={{ meta: { sort: ["cont_prof DESC"] } }} // ranking_cont_prof adalah kolom hasil RANK(), bukan kolom di database
      >
        <DatagridConfigurable
          rowClick={rowClickHandler}
          bulkActionButtons={false}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <TextField source="code" label="mine.label.code" />
          <TextField source="name" label="mine.label.name" />
          <NumberField source="distinct_month" label="Jumlah Bulan" />
          <NumberField source="quantity_box" label="Jumlah Belanja (Box)" />
          <NumberField
            source="average_profit"
            label={t("mine.label.profit_average")}
            options={{
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
          <NumberField
            source="cont_prof"
            label="Cont. Profit"
            options={{
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
          <TextField
            source="grade.month"
            label="Grade Bulan"
            textAlign="center"
          />
          <TextField
            source="grade.quantity"
            label="Grade Quantity"
            textAlign="center"
          />
          <TextField
            source="grade.average_profit"
            label="Grade Rata-Rata Profit"
            textAlign="center"
          />
          <TextField
            source="grade.cont_profit"
            label="Grade Cont. Profit"
            textAlign="center"
          />
          <TextField
            source="grade.loan_tenor"
            label="Grade Loan Tenor"
            textAlign="center"
          />
          <NumberField source="score" label="Score" />
        </DatagridConfigurable>
      </List>
    );
  }, [theme, showAside]);
  return list;
};

export default CustomerRankingList;
