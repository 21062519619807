import { useMemo, useState } from "react";
import {
  List,
  NumberField,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  BulkDeleteWithConfirmButton,
  useTheme,
  usePermissions,
} from "react-admin";
import TransportAddDialog from "../../components/transports/create/TransportAddDialog";
import TransportEditDialog from "../../components/transports/edit/TransportEditDialog";
import transportFilterList from "../../components/transports/filter/transportFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import TransportExpandList from "../../components/transports/expand/TransportExpandList";
import TableListActionsWithUpload from "../../components/tableListActions/TableListActionsWithUpload";
import TransportUploadDialog from "../../components/transports/upload/TransportUploadDialog";

const TransportList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const { permissions } = usePermissions();
  const [theme] = useTheme("light");

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={
          <TableListActionsWithUpload
            createHandler={handleClickOpenAdd}
            uploadHandler={handleClickOpenUpload}
            exporter={false}
          />
        }
        filters={transportFilterList(permissions)}
        sort={{ field: "created_at", order: "DESC" }}
        // queryOptions={{ meta: { sort: ["created_at ASC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
          expand={<TransportExpandList />}
          isRowExpandable={(row) => !!row.transport_costs.length}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField source="company.alias" label="mine.label.company" />
          ) : null}

          <TextField source="code" label="mine.label.code" />
          <TextField source="name" label="mine.label.name" />
          <TextField source="brand" label="mine.label.brand" emptyText="-" />
          <TextField source="type" label="mine.label.type" emptyText="-" />
          <TextField source="kind" label="mine.label.kind" emptyText="-" />
          <NumberField
            source="capacity"
            label="mine.label.capacity"
            emptyText="-"
          />
          <TextField source="plate" label="mine.label.plate" emptyText="-" />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    [theme]
  );
  return (
    <>
      {list}
      <TransportAddDialog open={openAdd} setOpen={setOpenAdd} />
      <TransportEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
      {openUpload ? (
        <TransportUploadDialog open={openUpload} setOpen={setOpenUpload} />
      ) : null}
    </>
  );
};

export default TransportList;
