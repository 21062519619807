import { useEffect, useMemo, useState } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  useTheme,
  usePermissions,
  NumberField,
} from "react-admin";
import accountReceivableCardFilterList from "../../components/accountReceivableCards/filter/accountReceivableCardFilterList";
import AccountReceivableCardExpandList from "../../components/accountReceivableCards/expand/AccountReceivableCardExpandList";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import MyDatagridBody from "../../components/common/MyDatagridBody";
import AccountReceivableCardFooterList from "../../components/accountReceivableCards/footer/AccountReceivableCardFooterList";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const ExpandAll = () => {
  useEffect(() => {
    const expandAllBtn = document.querySelector(
      "table.RaDatagrid-table .RaDatagrid-thead .RaDatagrid-headerRow .RaDatagrid-expandHeader .RaDatagrid-expandIcon:not(.RaDatagrid-expanded)"
    );
    if (expandAllBtn) {
      var clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      expandAllBtn.dispatchEvent(clickEvent);
    }
  }, []);
  return null;
};

const AccountReceivableCardList = () => {
  const { permissions } = usePermissions();
  const [theme] = useTheme("light");

  const list = useMemo(
    () => (
      <List
        title="mine.list_title.account_receivable_card"
        empty={false}
        actions={<DownloadAction />}
        filters={accountReceivableCardFilterList(permissions)}
        sort={{ field: "customers.code", order: "ASC" }}
        filterDefaultValues={{
          start_date: firstDate,
          end_date: lastDate,
        }}
        // queryOptions={{ meta: { sort: ["customers.created_at ASC"] } }} // overide default sort
      >
        <DatagridConfigurable
          // TODO semua data grid yg ada Grand Total harus diganti dengan cara ini
          body={<MyDatagridBody footer={<AccountReceivableCardFooterList />} />}
          bulkActionButtons={false}
          expand={<AccountReceivableCardExpandList />}
          isRowExpandable={(row) => {
            return row && !!row.customer_debt_items.length;
          }}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <TextField source="code" label="mine.label.customer_code" />
          <TextField source="name" label="mine.label.customer_name" />
          <ExpandAll />
          <NumberField source="initial" label="mine.label.initial_balance" />
          <NumberField source="debit" label="mine.label.debit" />
          <NumberField source="credit" label="mine.label.credit" />
          <NumberField source="final" label="mine.label.final_balance" />
        </DatagridConfigurable>
      </List>
    ),
    [theme]
  );
  return list;
};

export default AccountReceivableCardList;
