import * as React from "react";
import {
  TopToolbar,
  useDataProvider,
  useListContext,
  useResourceContext,
} from "react-admin";
import DownloadIcon from "@mui/icons-material/Download";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Button from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ViewListIcon from "@mui/icons-material/ViewList";
import { useMutation } from "react-query";

const DownloadAction = ({ handler, field, order }) => {
  const { filterValues, sort } = useListContext();
  const resource = useResourceContext();
  const dataProvider = useDataProvider();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const { data, mutate } = useMutation((prm) => {
    let init = resource + prm;
    if (Object.keys(filterValues).length) {
      init += "?filter=" + encodeURIComponent(JSON.stringify(filterValues));
    }

    init +=
      "&sort=" +
      encodeURIComponent(
        JSON.stringify([
          (field ? field : sort.field) + " " + (order ? order : sort.order),
        ])
      );
    return dataProvider.getListNoParams(init);
  });

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleDownloadExcel = (event) => {
    mutate("/excel");
  };
  const handleDownloadPdf = (event) => {
    mutate("/pdf");
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    if (data && data.link) {
      setOpen(false);
      const newlink = document.createElement("a");
      newlink.setAttribute("href", data.link);
      newlink.setAttribute("target", "_blank");
      document.body.appendChild(newlink);

      var clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      newlink.dispatchEvent(clickEvent);
      newlink.remove();
    }
  }, [data]);

  return (
    <TopToolbar
      sx={{
        minHeight: "54px !important",
        mb: 1,
        "& .MuiButtonBase-root": {
          border: "0.1rem solid",
        },
      }}
    >
      <Button
        ref={anchorRef}
        label="Download"
        sx={{ width: "fit-content", lineHeight: "1.5" }}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        startIcon={<DownloadIcon />}
        size="small"
      >
        Export
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
        sx={{ zIndex: 3, width: 320, maxWidth: "100%" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-end" ? "right top" : "right bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    // component="a"
                    // href={
                    //   process.env.REACT_APP_API_URL +
                    //   "/template/T_SetupMotif.xlsx"
                    // }
                    // target="_blank"
                    onClick={handleDownloadExcel}
                    sx={{ border: "none !important" }}
                  >
                    <ListItemIcon>
                      <ViewListIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Excel</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={handleDownloadPdf}
                    sx={{ border: "none !important" }}
                  >
                    <ListItemIcon>
                      <PictureAsPdfIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Pdf</ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </TopToolbar>
  );
};

export default DownloadAction;
