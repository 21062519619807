import {
  TextInput,
  maxLength,
  number,
  required,
  useDataProvider,
  useResourceContext,
  useTranslate,
  useNotify,
} from "react-admin";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import { useQuery } from "react-query";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";

const ReasonInput = ({ recordId, error }) => {
  const { setError } = useFormContext();
  const notify = useNotify();
  const resource = useResourceContext();
  const dataProvider = useDataProvider();
  const t = useTranslate();

  // CEK SUDAH PERNAH PRINT ATAU BLOM
  const uri =
    resource + "/print-invoice-need-reason?id=" + encodeURIComponent(recordId);
  const { data, isLoading } = useQuery([uri, "getListNoParams"], () =>
    dataProvider.getListNoParams(uri)
  );
  // CEK SUDAH PERNAH PRINT ATAU BLOM

  useEffect(() => {
    if (error) {
      if (error.body) {
        if (error.body.errors) {
          // TODO mungkin perlu dicoba pake komponen "npm install @hookform/error-message"
          // SEE: https://react-hook-form.com/docs/useformstate/errormessage
          // The shape of the returned validation errors must match the shape of the form
          Object.keys(error.body.errors).forEach((k, i) => {
            setError(k, { type: "custom", message: error.body.errors[k] });
          });
        }
      }
      notify(error.message.split(" ").join("_"), {
        type: "error",
      });
    }
  }, [error]);

  return (
    <>
      <Box
        sx={{
          fontWeight: "500",
          textTransform: "uppercase",
          pb: "20px",
        }}
      >
        <Typography
          component="span"
          sx={{
            backgroundColor: "#d32f2f",
            color: "#fff",
            padding: "1px 8px 0",
            borderRadius: "4px",
          }}
        >
          {t("mine.label.warning")}
        </Typography>
        {": "}
        <Typography component="span">
          Pastikan sudah cek data retur jual dan cash discount
        </Typography>
      </Box>
      <TextInput
        fullWidth
        autoFocus
        source="due"
        label="mine.label.due"
        variant="outlined"
        validate={[required(), number()]}
        sx={{ minWidth: 300 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {t("mine.label.day")}
            </InputAdornment>
          ),
        }}
      />
      <TextInput
        fullWidth
        source="plate"
        label="mine.label.plate_number"
        variant="outlined"
        validate={[required(), maxLength(20)]}
        sx={{ minWidth: 300 }}
      />
      {data && !isLoading ? (
        <TextInput
          fullWidth
          source="reason"
          label="mine.label.reason"
          multiline
          rows={3}
          variant="outlined"
          validate={[required(), maxLength(100)]}
        />
      ) : null}
    </>
  );
};
export default ReasonInput;
